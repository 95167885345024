import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";

import {
    // HomeOutlined,
    // ContainerOutlined,
    // BarChartOutlined,
    UserOutlined,
    AuditOutlined,
    SettingOutlined,
    DiffOutlined,
    AppstoreAddOutlined,
    ContactsOutlined,
    GlobalOutlined,
    FileSyncOutlined,
    // SecurityScanOutlined,
} from '@ant-design/icons';

// 外层
import MainAppLayout from '../layout/mainApp';
import {SubAppLayout} from '../layout';
import RegisterApp from '../layout/resgister';

// // 子应用 路由加载
// import './resgisterApps';

// 主应用 路由加载
import Login from '../views/user/login/index';
import SetPassword from '../views/user/setPassword/index';
import Register from '../views/user/register/index';
import MemberList from '../views/pass/organization/member/list';
import MemberDetail from '../views/pass/organization/member/detail/index2';
import OrganizationSetting from '../views/pass/organization/setting';

import businessdeliveryPlan from '../views/pass/business/deliveryPlan';
import businesssupplier from '../views/pass/business/supplier';
import businessproduct from '../views/pass/business/product';
import businessTaxArea from '../views/pass/business/taxArea';

// 工作台
import WorkspaceEmail from '../views/pass/workspace/email';
import WorkspaceNotice from '../views/pass/workspace/notice';
import WorkspaceReport from '../views/pass/workspace/report';
import WorkspaceExamine from '../views/pass/workspace/examine';

// 权限管理
import AuthorizationBaseApp from '@/views/pass/authorization/baseApp';
import AuthorizationApplet from '@/views/pass/authorization/applet';
import AuthorizationSuperRole from '@/views/pass/authorization/superRole';

// 外部路由
const loginRoutes = [
    {
        path: "/user/login",
        component: Login,
        title: '登录'
    },
    {
        path: "/user/setPassword",
        component: SetPassword,
        title: '忘记密码'
    },
    {
        path: "/user/register",
        component: Register,
        title: '注册'
    },
];

/*
* 应用内部路由，
* 主应用内部menu根据此数组渲染
* 头部menu单独设置数组
*
*/
const routes_pass = [
    {
        path: '/',
        component: RegisterApp,  // 显示头部导航数组 和 子应用挂载，子应用挂载位置 和 内部路由挂载位置保持一致
        routes: [
            {
                path: '/pass',
                component: MainAppLayout,  // 显示头部以下内容
                // exact: true,
                routes: [
                    {
                        path: '/pass/workSpace',
                        title: '工作台',
                        icon: <DiffOutlined />,
                        component: SubAppLayout,
                        // render: '/pass/workSpace/supplier',
                        // hidden: true,
                        routes: [
                            {
                                path: "/pass/workSpace/report",
                                component: WorkspaceReport,
                                icon: <ContactsOutlined />,
                                title: '汇报'
                            },
                            {
                                path: "/pass/workSpace/notice",
                                component: WorkspaceNotice,
                                icon: <AppstoreAddOutlined />,
                                title: '公告'
                            },
                            {
                                path: "/pass/workSpace/examine",
                                component: WorkspaceExamine,
                                icon: <GlobalOutlined />,
                                title: '审批'
                            },
                            {
                                path: "/pass/workSpace/email",
                                component: WorkspaceEmail,
                                icon: <FileSyncOutlined />,
                                title: '邮箱'
                            }
                        ]
                    },
                    {
                        path: "/pass/organization",
                        title: '组织管理',
                        icon: <UserOutlined/>,
                        component: SubAppLayout,
                        routes: [
                            {
                                path: "/pass/organization/member/list",
                                component: MemberList,
                                icon: <AuditOutlined/>,
                                title: '花名册'
                            },
                            {
                                path: "/pass/organization/member/detail",
                                component: MemberDetail,
                                hidden: true,
                                title: '详情'
                            },
                            {
                                path: "/pass/organization/setting",
                                component: OrganizationSetting,
                                icon: <SettingOutlined/>,
                                title: '组织管理'
                            }
                        ]
                    },
                    {
                        path: "/pass/business",
                        title: '业务配置管理',
                        icon: <DiffOutlined />,
                        component: SubAppLayout,
                        routes: [
                            {
                                path: "/pass/business/supplier",
                                component: businesssupplier,
                                icon: <ContactsOutlined />,
                                title: '供应商管理'
                            },
                            {
                                path: "/pass/business/product",
                                component: businessproduct,
                                icon: <AppstoreAddOutlined />,
                                title: '产品管理'
                            },
                            {
                                path: "/pass/business/taxArea",
                                component: businessTaxArea,
                                icon: <GlobalOutlined />,
                                title: '税源地管理'
                            },
                            {
                                path: "/pass/business/deliveryPlan",
                                component: businessdeliveryPlan,
                                icon: <FileSyncOutlined />,
                                title: '交付方案管理'
                            }
                        ]
                    },
                    {
                        path: "/pass/authorization",
                        title: '权限管理',
                        icon: <DiffOutlined />,
                        component: SubAppLayout,
                        routes: [
                            {
                                path: "/pass/authorization/baseApp",
                                component: AuthorizationBaseApp,
                                icon: <ContactsOutlined />,
                                title: '基础应用管理员'
                            },
                            {
                                path: "/pass/authorization/applet",
                                component: AuthorizationApplet,
                                icon: <AppstoreAddOutlined />,
                                title: '微应用管理员'
                            },
                            {
                                path: "/pass/authorization/superRole",
                                component: AuthorizationSuperRole,
                                icon: <GlobalOutlined />,
                                title: '超级管理员'
                            }
                        ]
                    }
                ]
            }
        ]
    }
]

//@ts-ignore
function RouteWithSubRoutes(route: any) {
    // console.log(route)
    let keys = {
        key: route.path,
        path: route.path,
        exact: route.exact || false,
        hidden: route.hidden
    }
    if(route.redirect) keys = {...keys, ...{render: () => <Switch><Redirect to={route.redirect} /></Switch>}}
    if(route.component) keys = {...keys, ...{component: (props: any) => <route.component {...props} routes={route.routes}/>}}
    return (
        <Route {...keys} />
    );
}



export const routesArr = routes_pass;

const allRoutes = [...loginRoutes, ...routes_pass];
// console.log(allRoutes)

const list = allRoutes.map((item, i) => {
    return RouteWithSubRoutes(item)
    // return <RouteWithSubRoutes key={i} {...item} />
})
// console.log(list)
export default () => (
    <Switch>
        {/*<Route exact path="/" render={() => (*/}
            {/*<Redirect to="/user/login" />*/}
        {/*)} />*/}
        {/*<Route path="/" component={RegisterApp} />*/}
        {list}
        {/*<Route component={() => <div>找不到</div>}/>*/}
    </Switch>
)
