//
const mouthL = []
for (let i=0; i<28; i++){
    mouthL.push({
        value: i+1,
        name: i+1
    })
}
const data = {
    // 头部菜单 所有 作为 全部映射
    menuList : [
        {
            path: '/pass/workSpace',
            title: '工作台',
            code: 'workSpace'
        },
        {
            path: '/pass/organization',
            title: '组织管理',
            code: 'organize'
        },
        {
            path: '/sign_helper',
            title: '签约助手',
            code: 'QYZS'
        },
        {
            path: '/deliver',
            title: '灵工交付系统',
            code: 'LHYG'
        },
        {
            path: '/pass/business',
            title: '业务配置管理',
            code: 'service_config'
        },
        {
            path: '/wage_split',
            title: '工资表拆分',
            code: 'GZCF'
        },
        {
            path: '/tools',
            title: '工具间',
            code: 'GJJ'
        },

        // {
        //     path: '/pass/workSpace',
        //     title: '流程管理',
        //     icon: <UserOutlined />
        // },
        // {
        //     path: '/pass/workSpace',
        //     title: '应用管理',
        //     icon: <UserOutlined />
        // },
        {
            path: '/pass/authorization',
            title: '权限管理',
            code: 'authority'
        },
    ],
    // 组织类别
    departType: [
        {
            name: '子公司',
            value: 1
        },
        {
            name: '部门',
            value: 2
        },
        {
            name: '门店',
            value: 3
        },
        {
            name: '事业部',
            value: 4
        },
        {
            name: '中心',
            value: 5
        },
        {
            name: '组',
            value: 6
        },
    ],
    // 性别
    sex: [
        {
            name: '男',
            value: 1
        },
        {
            name: '女',
            value: 2
        }
    ],
    // 业务管理 产品类型
    product_type: [
        {
            name: '雇用经济',
            value: '1'
        },
        {
            name: '非雇用经济',
            value: '2'
        },
        {
            name: 'SaaS售卖',
            value: '3'
        }
    ],
    // 业务管理 产品状态
    product_status: [
        {
            name: '已启用',
            value: '10'
        },
        {
            name: '已停用',
            value: '20'
        }
    ],
    // 业务管理 供应商性质
    supplier_nature: [
        {
            name: '内部',
            value: '0'
        },
        {
            name: '外部',
            value: '10'
        }
    ],
    // 业务管理 供应商状态
    supplier_status: [
        {
            name: '已启用',
            value: '10'
        },
        {
            name: '已停用',
            value: '20'
        }
    ],
    // 业务管理 税源地状态
    taxArea_status: [
        {
            name: '已启用',
            value: '10'
        },
        {
            name: '已停用',
            value: '20'
        }
    ],
    // 业务管理 交付方案状态
    deliveryPlan_status: [
        {
            name: '已启用',
            value: '10'
        },
        {
            name: '已停用',
            value: '20'
        }
    ],
    // 业务管理 交付方案状态
    kuaiDi: [
        {
            name: '顺丰快递',
            value: '1'
        },
        {
            name: '中通快递',
            value: '2'
        },
        {
            name: 'EMS',
            value: '2'
        },
        {
            name: '圆通快递',
            value: '2'
        },
        {
            name: '韵达快递',
            value: '2'
        },
        {
            name: '申通快递',
            value: '2'
        },
        {
            name: '京东快递',
            value: '2'
        }
    ],
    // 1-28
    mouthList: mouthL
}

export default data

