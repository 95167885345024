import React, {useEffect, useState} from "react";

import {
    Radio, Form, Input
} from 'antd';

import InputComponent from "@/components/inputBox";
import TableMock from './table_mock'


interface Interfaceprops {
    label: string,
    name: string,
    value?: any
}


const mockFlag = true

const TaxSelect = (props: any) => {
    const initList:Array<GLOBAL_DATA.InterfaceList> = [
        {
            first: 0,
            buttons: '<',
            last: 2000,
            buttonb: '<=',
            tax: '2'
        },
        {
            first: 2000,
            buttons: '<',
            last: -1,
            buttonb: '',
            tax: '3'
        },
    ]
    const [type, setType] = useState(0)

    const getList = (list: any) => {
        // console.log(list)
        props.onChange && props.onChange({[props.name + 'Value']: list})
    }
    const taxTypeChange = (data: any) => {
        setType(data.target.value)
        if(data.target.value === 10) {
            props.getForm.setFieldsValue({[props.name + 'Value']: ''})
        } else {
            props.getForm.setFieldsValue({[props.name + 'Value']: initList})
            getList(initList)
        }

    }

    useEffect(() => {
        // console.log(props.getForm.getFieldValue('taxSourceName'))
    }, [])


    const layoutOpe = {
        wrapperCol: { span: 8, offset: props.offset || 6 },
    }
    const layoutOpe1 = {
        wrapperCol: { span: 18, offset: props.offset || 6 },
    }

    return (
        <>
            <Form.Item
                label={props.label}
                name={props.name}
                rules={[{ required: mockFlag}]}
            >
                <Radio.Group onChange={taxTypeChange}>
                    <Radio value={10}>固定税率</Radio>
                    <Radio value={20}>阶梯税率</Radio>
                </Radio.Group>
            </Form.Item>

            {
                props.getForm.getFieldValue(props.name) === 10 &&
                <Form.Item {...layoutOpe} rules={[{ required: mockFlag}]} label="" name={`${props.name}Value`}>
                    <InputComponent placeholder='请输入固定税率' suffix="%"  style={{width: 200}}  />
                </Form.Item>
            }
            {
                props.getForm.getFieldValue(props.name) === 20 &&
                <Form.Item {...layoutOpe1} rules={[{ required: mockFlag}]} label="" name={`${props.name}Value`}>
                    {/*<TableMock onChange={getList} getValue={props.getValue} />*/}
                    <TableMock onChange={getList} getForm={props.getForm} name={`${props.name}Value`} />
                </Form.Item>
            }
        </>
    )
}

export default TaxSelect;
