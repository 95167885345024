import React from "react";
import {connect} from 'react-redux';
import {
     Input, Button, Drawer, message
} from 'antd';

import './adminManagement.less';

import {Dispatch} from 'redux';

import DepartAndMember from './departAndMember'
import SelectMemberList from  './selectMemberList'
import ManagementScope from './managementScope'
import BaseAppRole from './baseAppRole'
import SmallAppRole from './smallAppRole'
import {ajax} from "@/store/midPromise";

const { Search } = Input;

interface Interfaceprops {
    title: string,
    visible: boolean,
    onClose: () => void,
    userId?: number,
    type: string,
    selectName?: any,
    managementList?: any[],
    roleList?: any[],
    onChange?: (value: any, type: string) => void,
    onSuccess: () => void,
    publicInfo: any,
}
interface MyState {
    ajaxData: any,
    memberList: any[],
    selectMemberId: any,
    selectName: any,
    managementList: any[],
    roleList: any[],
    checkRoleList: any[],
    domObj: React.Component | null
}
interface AjaxData {
    userId: any,
    userIds: number[],
    departmentIds: number[],
    menuIds: number[],
    applyIds: number[],
    transferUserId?: number
}

let ajaxData: {searchName: string, deptId: string| number} = {
    searchName: '',
    deptId: ''
}
let allData: {
    selectIds: any[],
    radioId: number
} = {
    selectIds: [],
    radioId: -1
}
let allMemberList:any[] = []

class AdminClass extends React.Component<Interfaceprops, MyState> {
    constructor(props: Interfaceprops) {
        super(props);
        this.state = {
            ajaxData: {},
            memberList: [],
            selectMemberId: [],
            selectName: [],
            managementList: [],
            roleList: [],
            checkRoleList: [],
            domObj: null
        };
    }

    selectDepart = (value: number) => {
        ajaxData.deptId = value
        this.getMemberList()
    }
    getMemberList = () => {
        // 获取成员列表
        ajax.get('/auth/employee/manager/v2', {params: ajaxData}).then(res => {
            if(res.status !==200) return;
            allMemberList = allMemberList.concat(res.data)
            this.setState({memberList: res.data})
        })
    }
    onClose = () => {
        this.props.onClose()
    }

    deleteMember = (id: any) => {
        const arr = [...this.state.selectMemberId]
        const index = arr.indexOf(id)
        arr.splice( index, 1)
        this.setState({selectMemberId: arr}, () => this.changeSelectMemberList())
    }
    changeMemberList = (checkedValues: any, type?: string) => {
        // console.log(checkedValues)
        this.setState({selectMemberId: checkedValues}, () => this.changeSelectMemberList())
    }
    changeSelectMemberList = () => {
        let listmm: Array<any> = [];
        this.state.selectMemberId.forEach((item: number) => {
            for (let i = 0; i< allMemberList.length; i++) {
                if(item === allMemberList[i].userId) {
                    listmm.push(allMemberList[i])
                    allData.selectIds.push(allMemberList[i].userId)
                    break;
                }
            }
        })

        this.setState({
            selectName: listmm
        })
    }
    changeManageScope = (value: any) => {
        this.setState({managementList: value})
        this.props.onChange && this.props.onChange(value, 'managementList')
    }
    changeAppRoleList = (data: any) => {
        // console.log(data)
        this.setState({checkRoleList: data});
        this.props.onChange && this.props.onChange(data, 'roleList')
    }
    onSearch = (value: string) => {
         // this.setState({ajaxData: {...this.state.ajaxData, ...{searchName: value}}})
        ajaxData.searchName = value
        this.getMemberList()
    }

    addBaseSure = (data: any) => {
        ajax.post('/auth/admin/basic/add', data).then(res => {
            if(res.status !== 200) return
            message.success('添加成功')
            this.props.onSuccess()
        })
    }
    editBaseSure = (data: any) => {
        ajax.post('/auth/admin/basic/update', data).then(res => {
            if(res.status !== 200) return
            message.success('编辑成功')
            this.props.onSuccess()
        })
    }
    appletAdd_sure = (data: any) => {
        ajax.post('/auth/admin/micro/add', data).then(res => {
            if(res.status !== 200) return
            message.success('添加成功')
            this.props.onSuccess()
        })
    }
    appletEdit_sure = (data: any) => {
        ajax.post('/auth/admin/micro/update', data).then(res => {
            if(res.status !== 200) return
            message.success('编辑成功')
            this.props.onSuccess()
        })
    }
    superRole_add = (data: any) => {
        ajax.post('/auth/admin/super/add', data).then(res => {
            if(res.status !== 200) return
            message.success('添加成功')
            this.props.onSuccess()
        })
    }
    superRole_change = (data:any) => {
        ajax.post('/auth/admin/super/transfer', data).then(res => {
            if(res.status !== 200) return
            message.success('转移成功')
            this.props.onSuccess()
        })
    }

    sureData = () => {
        console.log(this.state.selectName)
        let data:AjaxData = {
            userId: this.props.userId,
            userIds: this.state.selectName.map((item: any) => item.userId),
            departmentIds: this.state.managementList,
            menuIds: this.state.checkRoleList,
            applyIds: this.state.checkRoleList,
            transferUserId: allData.radioId
        }
        console.log(data)
        switch (this.props.type) {
            case 'add': this.addBaseSure(data); break;
            case 'edit': this.editBaseSure(data); break;
            case 'superRole_add': this.superRole_add(data); break;
            case 'applet_add': this.appletAdd_sure(data); break;
            case 'applet_edit': this.appletEdit_sure(data); break;
            case 'superRole_change': this.superRole_change(data); break;
        }
    }

    componentDidMount () {
        this.setState({
            selectName: this.props.selectName || [],
            managementList: this.props.managementList || [],
            roleList: this.props.roleList || [],
            checkRoleList: this.props.roleList || [],
        }, () => {
            // console.log(this.props.managementList)
        })
        // allData.selectIds = this.props.selectName || []
        this.getMemberList()
    }
    render () {

        const domObj = {
            search: <Search placeholder="搜索管理员" style={{width: 320}} onSearch={this.onSearch} key={'search'} />,
            departAndMember: <DepartAndMember list={this.state.memberList} key={'2'}
                                              selectList={this.state.selectMemberId}
                                              onSelect={this.selectDepart}
                                              onChange={this.changeMemberList} />,
            departAndMemberRadio: <DepartAndMember list={this.state.memberList} key={'radio'}
                                                   checkedType={'radio'}
                                              selectList={this.state.selectMemberId}
                                              onSelect={this.selectDepart}
                                                   onSelectRadio={(id)=> allData.radioId = id} />,
            selectMember: <SelectMemberList list={this.state.selectName} closable={true} key={'3'}
                                            onDelete={this.deleteMember} />,
            managementScope:   <ManagementScope list={this.state.managementList} key={'4'}
                                                onChange={this.changeManageScope} /> ,
            managementScopeDisabled:   <ManagementScope list={this.state.managementList} key={'4'}
                                                disabled /> ,
            baseAppRole: <BaseAppRole list={this.props.roleList||[]} onChange={this.changeAppRoleList}
                                      key={'5'} />,
            smallAppRole: <SmallAppRole list={this.props.roleList||[]} onChange={this.changeAppRoleList} key={'6'} />,
        }
        return (
            <Drawer
                width={700}
                placement="right"
                closable={true}
                title={this.props.title}
                onClose={this.onClose}
                visible={this.props.visible}
            >
                { // 添加基础管理员
                    this.props.type === 'add' &&
                    [
                        domObj.search,
                        domObj.departAndMember,
                        domObj.selectMember,
                        domObj.managementScope,
                        domObj.baseAppRole
                    ]
                }
                { // 编辑基础管理员
                    this.props.type === 'edit' &&
                    [
                        domObj.managementScope,
                        domObj.baseAppRole
                    ]
                }
                { // 添加微应用管理员
                    this.props.type === 'applet_add' &&
                    [
                        domObj.search,
                        domObj.departAndMember,
                        domObj.selectMember,
                        domObj.smallAppRole
                    ]
                }
                { // 编辑微应用管理员
                    this.props.type === 'applet_edit' &&
                    [
                        domObj.smallAppRole
                    ]
                }
                { // 添加超级管理员
                    this.props.type === 'superRole_add' &&
                    [
                        domObj.search,
                        domObj.departAndMember,
                        domObj.selectMember,
                        <div className="management-scope">
                            <div className="title">管理范围</div>
                            <div className="box">
                                {this.props.publicInfo.parentDepartName}
                            </div>
                        </div>
                    ]
                }
                {
                    //  转移超级管理员
                    this.props.type === 'superRole_change' &&
                    [
                        domObj.search,
                        domObj.departAndMemberRadio,
                        domObj.managementScopeDisabled
                    ]
                }
                <div className={'c_mt_20 c_flex c_flex_r'}>
                    <Button onClick={this.props.onClose} className={'c_mr_10'}>取消</Button>
                    <Button type={'primary'} onClick={this.sureData}>确定</Button>
                </div>
            </Drawer>
        )
    }

}

const mapStateProps = (state: any) => {
    return {
        publicInfo: state.golbal.publicInfo,
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(AdminClass);
