// 组织类别选择组件 后期根据需求修改
import React, {useEffect, useState} from "react";

import {
    Select,
} from 'antd';

import {connect} from 'react-redux';

import {Dispatch} from 'redux';
import {departType_data} from "@/store/model/reducers";

interface Interfaceprops {
    value?: string | string[],
    type?: 'check',
    placeholder?: string,
    onChange: (data: any) => void,
    departType_data: () => void,
    departType: Array<{
        typeName: string,
        id: number
    }>
}

const { Option } = Select;

const children:number[] = [];
for (let i = 10; i < 36; i++) {
    children.push(i);
}

const TreeSelectOrganization = (props: Interfaceprops) => {
    const [departList, setDepartList] = useState<Array<{ name: string, value: number }>>([])
    // @ts-ignore
    const onChange = (info) => {
        props.onChange(info)
    }

    useEffect(() => {
        props.departType_data()
    }, [])

    useEffect(() => {
        const arr = props.departType.map(item => {
            return {
                value: item.id,
                name: item.typeName
            }
        })
        setDepartList(arr)
    }, [props.departType])

    const defaultProps = {
        value: props.value,
        onChange: onChange,
        placeholder: props.placeholder || '请选择',
        style: {width: '100%'}
    };

    return (
        <Select style={{ width: '100%' }} {...defaultProps}>
            {
                departList.map((item) => <Option key={item.value} value={item.value}>{item.name}</Option>)
            }
        </Select>
    )
}


const mapStates = (state: any) => {
    return {
        member_list: state.index.member_list,
        arrConfig: state.golbal.arrConfig,
        departType: state.index.departType
    }
}
const mapActions = (dispatch: Dispatch) => {
    return {
        departType_data: () => {
            dispatch(departType_data())
        },
    }
};

export const TreeSelectOrganizationType = connect(mapStates, mapActions)(TreeSelectOrganization);
