import React, {useEffect, useState, useCallback} from "react";
import {connect} from 'react-redux';
import {
    Input, Button, Table
} from 'antd';

import './index.less';

import {Dispatch} from 'redux';

import AdminManagement from '../components/adminManagement'
import {RouteComponentProps} from "react-router"
import {ajax} from "@/store/midPromise";
import {steamroller} from "@/statics/js/libs";

const { Search } = Input;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    api_all: (name:string, data: API.InterfaceData) => void,

}
function mapArr (data: any) {
    if(!data) return
    return  data.map((item: any) => {
        if(item.children) {
            return [item.id, mapArr(item.children)]
        } else {
            return item.id
        }
    })
}
const Product:React.FC<Interfaceprops> = (props) => {

    const [resData, setresData] = useState({total: '', data: [], loading: true})
    const [opeData, setopeData] = useState({visible: false, title: "", type: "", userId: -1})
    const [ajaxData, setAjaxData] = useState({searchName: '', page: 1, rows: 10, type: 4})

    const edit = (data:any) => {
        const arr = steamroller(mapArr(data.microList)|| [])
        setopeData({...opeData, ...{visible: true, title: '编辑' + data.name, type: 'applet_edit',
                userId: data.userId,
                roleList: arr
            }})
    }
    const addAdmin = () => {
        setopeData({...opeData, ...{visible: true, title: '添加管理员', type: 'applet_add',selectName: []}})
    }

    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            width: 220,
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            width: 120,
        },
        {
            title: '部门',
            dataIndex: 'departmentName',
            width: 100,
        },
        {
            title: '应用',
            dataIndex: 'microList',
            width: 120,
            render: (data:any[]) => data.map(item => <div key={item.id}>{ item.name }</div>)
        },
        {
            title: '操作',
            width: 180,
            fixed: "right" as "right",
            render: (data: any) => <>
                <Button type={'link'} size={'small'} onClick={() => edit(data)}>编辑</Button>
            </>
        }
    ];
    const tableOptions = {
        rowKey: 'userId',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: columns,
        dataSource: resData.data,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }

    const init = useCallback(() => {
        ajax.get(`/auth/admin/list`, {params: ajaxData}).then((res: any) => {
            setresData((r) => {
                return {...r, ...{loading: false}}
            })
            if(res && res.status === 200) setresData({...res})
            console.log(res)
        })
    }, [ajaxData])
    useEffect (() => {
        init()
    }, [ajaxData, init])

    return <div>
        <div className="content-page">

            <div className='c_flex c_flex_up c_flex_rl' style={{marginBottom: 20}}>
                <div>
                    <Search placeholder="搜索管理员" style={{width: 320}}
                            onSearch={value => setAjaxData({...ajaxData, ...{searchName: value, page: 1}})} enterButton/>
                </div>

                <Button type={'primary'} onClick={addAdmin}>添加管理员</Button>
            </div>

            <Table {...tableOptions}/>

            {/*// 管理员操作 */}
            <AdminManagement {...opeData} onClose={() => setopeData({...opeData, ...{visible: false}})}
                             onSuccess = { () => {
                                 setopeData({...opeData, ...{visible: false}});
                                 init()
                             }} />

        </div>
    </div>
}

const mapStateProps = (state: any) => {
    return {

    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(Product);
