import React, {useCallback, useEffect, useState} from 'react';

import {withRouter} from "react-router-dom";

import {
    DownOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Menu, Dropdown, Drawer, Modal, Skeleton, Avatar, Divider, message} from 'antd';

import Logo from '@/statics/imgs/logo.png';
import {Dispatch} from "redux";
import {connect} from "react-redux";
import MenuNav from '../menu'
import InfoTabs from './tabs'
import ModifyAccount from './modifyAccount'
import ModifyPassword from './modifyPassword'
import './header.less';
import {ajax} from "@/store/midPromise";
import SelectCompany from "@/components/selectCompany";

const style = {
    c_header: {
        height: '70px',
        borderBottom: '1px solid #f0f0f0',
        background: '#fff',
        position: 'absolute' as 'absolute',
        width: '100%',
        zIndex: 22
    },
    c_name: {
        fontSize: 16,
        marginLeft: '10px',
        fontWeight: 600,
        color: 'rgba(0,0,0,.85)'
    }
}
const PageHeaderContent: React.FC<{ currentUser: any }> = ({currentUser}) => {
    const loading = currentUser && Object.keys(currentUser).length;
    if (!loading) {
        return <Skeleton avatar paragraph={{rows: 2}} active/>;
    }
    return (
        <div className='pageHeaderContent'>
            <div className={'avatar'}>
                <Avatar size="large" src={
                    currentUser.avatar ||
                    'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png'
                }/>
            </div>
            <div className={'content'}>
                <div className={'contentTitle'}>
                    {currentUser.employeeName}
                </div>
                <div className='info'>
                    <span>部门 {currentUser.departmentName}</span>
                    <span>岗位 {currentUser.jobTitle}</span>
                    <span>手机号 {currentUser.mobile}</span>
                    <span>入职日期 {currentUser.joinedAt}</span>
                </div>
            </div>
        </div>
    );
};

function Header(props: any) {

    const [infoVisible, setInfoVisible] = useState(false)
    const [accountVisible, setAccountVisible] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [resData, setResData] = useState({})
    const [selVisible, setselVisible] = useState(false)

    const companyList = JSON.parse(localStorage.getItem('companyList') || '[]')

    const changeCompany = () => {
        setselVisible(true)
    }
    const menuClick = (data: any) => {
        // console.log(data)
        switch (data.key) {
            case '0':
                setInfoVisible(true);
                break;
            case '1':
                setAccountVisible(true);
                break;
            case '2':
                setPasswordVisible(true);
                break;
            case '3':
                loginOut();
                break;
            case '4':
                changeCompany();
                break;
        }
    }

    const loginOut = () => {
        props.history.push('/user/login');
        localStorage.setItem('app_role', '')
    }
    const sureModifyAccount = useCallback(() => {

    }, [])

    const init = () => {

        ajax.get('/auth/employee/profile').then(res => {
            if (res.status !== 200) return;
            setResData(res.data)
        })
    }
    useEffect(() => {
        init()
    }, [])


    return (
        <header className={'c_flex c_flex_rl c_flex_up'} style={style.c_header}>
            <div style={{marginLeft: '20px'}}>
                <img src={Logo} alt="logo" style={{width: '40px'}}/>
                <span style={style.c_name}>霖珑工作中心</span>
            </div>
            <div className='c_flex c_flex_rl c_flex_grow c_flex_up' style={{padding: '0 20px 0 100px'}}>
                <div>
                    <MenuNav routesArr={props.routesArr} mode="horizontal"/>
                </div>
                <div className='c_flex'>
                    <Dropdown overlay={
                        <Menu onClick={menuClick}>
                            <Menu.Item key="0">
                                个人档案
                            </Menu.Item>
                            <Menu.Item key="1">
                                修改登录账号
                            </Menu.Item>
                            <Menu.Item key="2">
                                修改密码
                            </Menu.Item>
                            {
                                companyList.length > 1 &&
                                <Menu.Item key="4">
                                    切换公司
                                </Menu.Item>
                            }
                            <Menu.Divider/>
                            <Menu.Item key="3">
                                退出登录
                            </Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <div>
                            <Avatar src={props.publicInfo.avatar}/>
                            <span className={'mouse_hand c_ml_10'}>{props.publicInfo.employeeName}
                                <DownOutlined/></span>
                        </div>
                    </Dropdown>
                </div>
            </div>

            <Drawer
                title="个人档案"
                placement="right"
                closable={true}
                onClose={() => setInfoVisible(false)}
                visible={infoVisible}
                width={1000}
            >
                <PageHeaderContent currentUser={resData}></PageHeaderContent>
                <Divider/>
                <InfoTabs currentUser={resData}></InfoTabs>
            </Drawer>

            <Modal title="修改登录账号" visible={accountVisible} width={800} footer={null}
                   onOk={sureModifyAccount} onCancel={() => setAccountVisible(false)}>
                <ModifyAccount></ModifyAccount>
            </Modal>

            <ModifyPassword visible={passwordVisible} onCancel={() => setPasswordVisible(false)}
                            onSuccess={() => props.history.push('/')}/>
            {/* 切换公司*/}
            <SelectCompany flag={selVisible} onClose={() => {
                setselVisible(false)
            }} onSuccess={() => {
                window.location.reload();
                props.history.push('/pass/workSpace');
            }}/>

        </header>
    );
}

const mapStateProps = (state: any) => {
    return {
        publicInfo: state.golbal.publicInfo,
        customer_info: state.golbal.customer_info
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {}
};

export default connect(mapStateProps, mapDispatchToProps)(withRouter(Header))
