import React, {useEffect, useState
    // , useCallback
} from "react";
import {connect} from 'react-redux';
import {
     Checkbox,
    List, Avatar
} from 'antd';

import {api_index} from '@/store/model/reducers/index'
import {Dispatch} from 'redux';

import './adminManagement.less'

interface Interfaceprops {
    api_all: (name:string, data: API.InterfaceData) => void,
    list: any[],
    onChange?: (data: any) => void,
}
interface ListROLE{
    id: number,
    name: string,
    all: boolean,
    checked: false,
    children: any[]
}
const Admin: React.FC<Interfaceprops> = (props) => {
    const [value, setvalue] = useState<any[]>([])
    const [showList, setShowList] = useState<ListROLE[]>([])

    const menuData = JSON.parse(localStorage.getItem('menuList')|| '{}')
    let listRole:Array<ListROLE> =  menuData.applyList;


    const onChange = (value:any) => {
        setvalue(value)
        props.onChange&&props.onChange(value)
    }
    useEffect(() => {
        // console.log(props.list)
        setvalue(props.list)
        props.onChange&&props.onChange(props.list)
        setShowList(listRole)
    }, [])

    return (
        <div className="baseApp-role">
            <div className="title">微应用权限</div>
            <div className="box">
                <Checkbox.Group style={{ width: '100%' }}
                                value={value}
                                onChange={onChange}>

                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={showList}
                    renderItem={item => (
                        <List.Item
                            actions={[<Checkbox value={item.id}>全部管理权限</Checkbox>]}
                        >
                            <div className={'c_flex c_flex_up '}>
                                <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                                <span className={'c_ml_20'} style={{fontSize: 15, fontWeight: 500}}>{item.name}</span>
                            </div>
                        </List.Item>
                    )}
                />
                </Checkbox.Group>
            </div>
        </div>
    )
}

const mapStateProps = (state: any) => {
    return {
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        api_all: (name:string, data: API.InterfaceData, ) => {
            dispatch(api_index({...data})(name))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Admin);
