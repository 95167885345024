import React from 'react';
import Logo from '../statics/imgs/logo.png'
import './less/userLayout.less'

export const App = (props: any) => {
  return (
    <div className="c_flex c_flex_col layout-user"  >
        {/*{console.log(props.children)}*/}
        <div className={'c_back_fff'} style={{width: '400px', margin: '0 auto', padding: '40px 30px 20px'}}>
          <div className='header'  >
              <img className='logo' src={Logo} alt="Logo"/>
              <h2>霖珑工作中心</h2>
          </div>
          <div className="propchildren">
              {props.children}
          </div>

            <div id='subapp-container'><div id='sub-app'></div></div>
        </div>
    </div>
  );
};
