import {ajax} from '@/store/midPromise'

export const steamroller = (arr:any[]) => {
    while(arr.some(item=> Array.isArray(item))){
        arr=[].concat(...arr)
    }
    return arr
}
export const getQuery = (str: string, name: string) => {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    const r = str.substr(1).match(reg);
    return r != null? unescape(r[2]): null;
}

export const getHeaderValue = (str: string, name: string) => {
    if (!str) return null
    const reg = new RegExp('(^|; )' + name + '=([^;]*)(;|$)', 'i');
    const r = str.substr(1).match(reg);
    return r != null? decodeURIComponent(r[2]): null;
}

export const getValue = (list: any[], typeName: string, typeValue: string, getId?: string) => {
    let value
    list.forEach((item) => {
        if(item[typeName] === String(typeValue)){
            value = getId? item[getId]: item
        }
    })
    return value
}

export const toFormData = (params: any) => {
    const formData = new FormData();
    Object.keys(params).forEach((key) => {
        formData.append(key, params[key]);
    });
    return formData
}

export const getStatus = (status: number|string, text: boolean, handle: boolean) => {
    if (handle && text) {
       return String(status) === '20'? '启用': '停用'
    } else if (handle && !text) {
        return String(status) === '20'? 10: 20
    } else if (text) {
        return String(status) === '10'? '启用': '停用'
    } else {
        return String(status) === '10'? 10: 20
    }
}

export const downFile = (content: any, name: any, url?: string) => {
    const elink = document.createElement("a");
    elink.style.display = "none";
    elink.download = name;
    elink.target = '_blank'
    if (url) elink.href = url;
    if(content) {
        const blob = new Blob([content]);
        elink.href = URL.createObjectURL(blob);
    }
    document.body.appendChild(elink);
    elink.click();
    document.body.removeChild(elink);
}

export const downloadFile = (type: string, succ: () => void, id = 0) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')

    // console.log(type)
    throttle(getStatus(), 2000)
    function getFile(url: string) {
        ajax.get(`${url}`, {responseType: 'blob'}).then((res: any) => {
            // console.log(res)
            if(res.headers){
                succ()
                const name = getHeaderValue(res.headers['content-disposition'], 'filename')
                downFile(res.data, name)
            }
        })
    }

    function fd() {
        ajax.get(`/Non-eeds/check/${APINAME}/getStatus/${type}`).then((res: any) => {
            // console.log(res)
            if(res && res.status === 200 && res.data){
                // const name = getHeaderValue(res.headers['content-disposition'], 'filename')
                getFile(res.data)
            } else {
                setTimeout(fd, 2000)
            }
        })
    }
   function getStatus() {
       ajax.get(`/Non-eeds/check/${APINAME}/export/${id}/${type}`).then((res: any) => {
           if(res && res.status === 200) {
               setTimeout(fd, 2000)
           }
       })
   }
}

export const debounce = function (fn: (data?: any) => void, delay: number) {
    let timer: number;

    return function() {
        // @ts-ignore
        let context = this;
        const args = arguments;

        clearTimeout(timer);
        // @ts-ignore
        timer = setTimeout(function(){
            // @ts-ignore
            fn.apply(context, args);
        }, delay)
    }
}

export const throttle = function(func: any, delay: number){
    let prev = Date.now();
    return function(){
        // @ts-ignore
        const context = this;
        const args = arguments;
        const now = Date.now();
        if(now-prev>=delay){
            // @ts-ignore
            func.apply(context,args);
            prev = Date.now();
        }
    }
}

export const regs = {
    phone:  /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
    ids:  /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{6,100}$/
}
