
import React, {useEffect, useState,
    // useCallback, useRef
} from "react";
import {connect} from 'react-redux';
import {
    Checkbox, Row, Col, Avatar,
    Radio
} from 'antd';

import {api_index} from '@/store/model/reducers/index'
import {Dispatch} from 'redux';

import './adminManagement.less'
import DepartList from "@/views/pass/authorization/components/departList";

interface Interfaceprops {
    api_all: (name:string, data: API.InterfaceData) => void,
    list: any[],
    selectList: any[],
    onChange?: (list: any, type?: string) => void,
    onSelect: (value: number) => void,
    checkedType?: string,
    onSelectRadio?: (id: number) => void
}

// let oldList:any[] = []
const Admin: React.FC<Interfaceprops> = (props) => {
    const [radioValue, setRadioValue] = useState(-1)

    let allValues:any[] = []
    const mapList = (data: Array<any>) => {
        data.forEach(item => {
            allValues.push(item.userId)
        })
    }
    mapList(props.list)

    const selectMember =(checkedValues: any) => {
        const arr = [...props.selectList]
        // console.log(arr, checkedValues)
        // 准则就是 checkedValues 有的， arr 里面也必须有， checkedValues 没有的，arr 里面也没有
        // checkedValues 没有的 ，要 对比 allValues
        let addList:any[] = [], noExitList:any[] = [], resultList:any[] = [] ;
        // checkedValues 有的
        checkedValues.forEach((item:any) => {
            if(arr.indexOf(item)<0) {
                addList.push(item)
            }
        })
        // checkedValues 没有的
        allValues.forEach((item) => {
            if(checkedValues.indexOf(item)<0) {
                noExitList.push(item)
            }
        })
        // 加上新增的
        resultList = arr.concat(addList)
        // 去掉没有的 取 补集
        arr.forEach(item => {
            if(noExitList.indexOf(item)>=0) {
                resultList.splice(resultList.indexOf(item), 1)
            }
        })
        props.onChange && props.onChange(resultList)
    }
    const onSelect = (value: number) => {
        // console.log(value)
        props.onSelect(value)
    }
    const selectMemberRadio = (e:any) => {
        // console.log(e)
        const value = e.target.value
        setRadioValue(value)
        props.onSelectRadio&&props.onSelectRadio(value)
    }
    useEffect(() => {

    }, [])

    return (
        <div className="select-member">
            <div className="depart-box">
                <div className='title'>部门列表</div>
                <DepartList onSelect={onSelect} />
            </div>
            <div className="member-list">
                <div className='title'>成员列表</div>
                {
                    <div className="c_pl_5">

                        {
                            props.checkedType === 'radio' ?
                                <Radio.Group onChange={selectMemberRadio} value={radioValue}>
                                    {
                                        props.list.map((item) => <Col span={24} key={item.userId}>
                                            <Radio value={item.userId}>
                                                <Avatar size={32} src={
                                                    item.avatar ||
                                                    "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"} />
                                                <span className="c_ml_5">{item.employeeName}</span>
                                            </Radio>
                                        </Col>)
                                    }
                                </Radio.Group> :
                            <Checkbox.Group onChange={selectMember} value={props.selectList}>
                                <Row>
                                    {
                                        props.list.map((item) => <Col span={24} key={item.userId}>
                                            <Checkbox value={item.userId}>
                                                <Avatar size={32} src={
                                                    item.avatar ||
                                                    "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"} />
                                                <span className="c_ml_5">{item.employeeName}</span>
                                            </Checkbox>
                                        </Col>)
                                    }
                                </Row>
                            </Checkbox.Group>
                        }

                    </div>
                }
            </div>
        </div>
    )
}

const mapStateProps = (state: any) => {
    return {
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        api_all: (name:string, data: API.InterfaceData, ) => {
            dispatch(api_index({...data})(name))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Admin);
