

import React, {useEffect, useState} from "react";

import {
    Input, Select, message, InputNumber
} from 'antd';
// import InputNumber from '@/components/antd/inputNumber'

import './table.less'
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {api_index, reset_customer_detail} from "@/store/model/reducers";

const { Option } = Select;

interface InputProps {
    onChange: (list: any) => void,
    getValue?: any,
    getForm?: any,
    name: string,
    reset_data: (data: any) => void
}

function Sel(props:any) {
    // console.log(props)
    if (props.leftSym) {
        return (
            <Select {...props} disabled>
                <Option value="<" >大于</Option>
                <Option value="<=">大于等于</Option>
            </Select>
        )
    } else {
        return (
            <Select {...props} >
                <Option value="<">小于</Option>
                <Option value="<=">小于等于</Option>
            </Select>
        )
    }
}

const TableMock = (props: InputProps) => {
    // console.log(props)
    const initList:Array<GLOBAL_DATA.InterfaceList> = [
        {
            first: 0,
            buttons: '<',
            last: 2000,
            buttonb: '<=',
            tax: '2'
        },
        {
            first: 2000,
            buttons: '<',
            last: -1,
            buttonb: '',
            tax: '3'
        },
    ]

    // const [first, setFirst] = useState({sel: '1', value: 1000})
    // const [end, setEnd] = useState('')
    // const [active, setActive] = useState('')

    const [list, setList] = useState([...initList])

    const changeInput = () => {
        const newList  = [...list]
        newList.forEach((item, index) => {
            // 下个范围的input值，改动为上个范围的最大值
            if(newList[index+1]){
                if(newList[index+1].first !== item.last ){
                    // @ts-ignore
                    newList[index + 1].first = item.last
                }
                newList[index+1]['buttons'] = newList[index]['buttonb'] === '<'? '<=': '<'
            }
        })
        props.onChange(newList)
    }

    const selChange = (key: any, index: number) => {
        const newList  = [...list]
        newList[index]['buttonb'] = key
        setList(newList)
        changeInput()
    }
    const patrn = /^\d+(\.\d+)?$/;

    const numChange = (value:any, index: number) => {
        console.log(value, !patrn.exec(value))
        if (!patrn.exec(value)){return;}
        const newList  = [...list]
        newList[index]['last'] = value
        // console.log(value, newList[index]['last'], newList)
        // newList[index+1]['first'] = num
        // newList[index+1]['buttons'] = newList[index]['buttonb'] === '1'? '2': '1'
        setList(newList)
        changeInput()
        // props.onChange(newList)
    }
    const changeTax = (e: any, index: number) => {
        const newList  = [...list]
        newList[index]['tax'] = e.target.value
        setList(newList)
        changeInput()

        // props.onChange(newList)
    }
    const addList = () => {
        const newList  = [...list]
        const len = newList.length
        const first = Number(newList[len-2]['last'])
        const buttons = newList[len-2]['buttonb'] === '<'? '>=': '>'
        const buttonb = newList[len-2]['buttonb']
        if(Number(list[len-2]['last']) < list[len-2]['first'] && len>2) {
            message.error('请输入正确的收入额，在进行添加')
            return
        }
        newList.splice(len-1, 0, {
            first,
            buttons,
            last: 0,
            buttonb,
            tax: ''
        })
        setList(newList)
        changeInput()
        // props.onChange(newList)
    }
    const removeList = (index: number) => {
        const newList  = [...list]
        newList.splice(index, 1)
        setList(newList)
        changeInput()
        // props.onChange(newList)
    }

    const onerror = (e:any, index: number) => {
        const newList  = [...list]
        console.log(newList)
        const value = newList[index]['last']
        const nextValue = newList[index +1 ] && newList[index +1 ]['last']
        const preValue = newList[index -1 ] && newList[index -1 ]['last']
        const resetNum = () => {
            newList[index]['last'] = 0
            setList(newList)
        }
        if ( Number(nextValue) <= Number(value) && index < newList.length-2 ) {
            // 输入值大于下个范围最大值
            message.error('输入值大于下个范围最大值');
            resetNum()
        }
        // console.log(value, preValue, value <= preValue)
        if( Number(value) <= Number(preValue)){
            // 输入值小于上个范围最大值
            message.error('输入值小于上个范围最大值');
            resetNum()
        }
    }

    useEffect(() => {
        // console.log(JSON.parse(props.getValue))
        const data = props.getForm.getFieldValue(props.name)
        if (Object.prototype.toString.call(data) === '[object Array]') setList(data)
        if (Object.prototype.toString.call(data) === '[object String]') {
            setList(JSON.parse(data))
            // props.reset_data()
            props.onChange(JSON.parse(data))
        }
    }, [])

    return (
        <>
            <div className={'c_border c_mb_20'}>
                <div className='c_flex c_flex_up tr'>
                    <div className='clu1'>收入额阶梯</div>
                    <div className='clu2'>税率</div>
                    <div className='clu3'>操作</div>
                </div>

                {

                    list.length> 0&&list.map((item, index) =>
                        <div className='c_flex c_flex_up' key={index} >
                            <div className='c_flex c_flex_up clu1'>
                                <div className='clu1_1' >
                                    <Input value={item.first} disabled prefix="¥" />
                                </div>
                                <div className='c_pl_5 clu1_2'>
                                   <Sel value={item.buttons} style={{width: 100}} leftSym={true}></Sel>
                                </div>

                                <div className='c_pl_5 clu1_3'>收入额</div>

                                <div className='c_pl_5 clu1_4'>
                                    {
                                        Number(item.last) >= 0 &&
                                        <Sel value={item.buttonb} style={{width: 100}} onChange={(key: string) => selChange(key, index)} leftSym={false} ></Sel>
                                    }
                                </div>
                                <div className='c_pl_5 clu1_5'>
                                    {/*{item.last}*/}
                                    {
                                        Number(item.last) >= 0 &&
                                        <InputNumber value={Number(item.last)}
                                                     formatter={value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                     onBlur={(e) => onerror(e, index)}
                                            onChange={(value:any) => numChange(value, index)}/>
                                    }
                                </div>
                            </div>

                            <div className={'clu2'} >
                                <Input value={item.tax} suffix="%" style={{width: 90}}
                                       onChange={(e) => changeTax(e, index)}
                                />
                            </div>

                            {
                                index === 0&&
                                <div className={'clu3'} onClick={addList}><a type='primary'>增加</a></div>
                            }
                            {
                                index>0 && index < list.length - 1 &&
                                <div className={'clu3'} onClick={() => removeList(index)}><a type={'primary'}>删除</a></div>
                            }
                            {
                                index === list.length - 1 &&
                                <div className={'clu3'}></div>
                            }
                        </div>
                    )
                }

            </div>

        </>
    )
}


const mapStateProps = (state: any) => {
    return {

    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        reset_data: (data: any) => {
            dispatch(reset_customer_detail(data))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(TableMock)
