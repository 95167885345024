import React, { useState } from "react";
import { UserLayout } from "@/layout";
import {api_index} from "@/store/model/reducers/index";
import { connect } from "react-redux";
import './index.less';
import {
    Form, Input,
    Button, Tabs, message
} from 'antd';
import {
  UserOutlined,
    LockOutlined,
    MessageOutlined
} from '@ant-design/icons';
import { Dispatch } from "redux";
import {RouteComponentProps} from "react-router";
import {public_info} from "@/store/model/reducers/golbal";
import {ajax} from "@/store/midPromise";
const { TabPane } = Tabs;

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

interface Interfaceprops extends RouteComponentProps{
    userLogin: (data: any) => void,
    get_public: () => void,
}

let phone = ''
const Index:React.FC<Interfaceprops> = (props) => {
  // console.log(props)
    const [formLogin] = Form.useForm()
    const [formPassword] = Form.useForm()
  const [activeKey, setactiveKey] = useState('1')
    const [msgText, setmsgText] = useState('获取验证码')
    const [msgdisabled, setmsgdisabled] = useState(false)

    const getCode = (t: number) => {
        if(t<=0) {
            setmsgdisabled(false)
            setmsgText(`重新获取`)
            return
        }
        setmsgdisabled(true)
        setmsgText(`${t}s后重新获取`)
        setTimeout(() => getCode(t-1), 1000)
    }
    const getMsg = () => {
        phone = formLogin.getFieldValue('phone')
        phone = phone && phone.replace(/\s+/g,"");
        if(!phone) return
        if (phone.length !== 11) return
        getCode(60)
        ajax.post('/auth/getCode', {phone: phone}).then((res: any) => {
            if(!(res && res.status === 200)) getCode(0)
        })
    }

  const onFinish = (values: any) => {
      phone = formLogin.getFieldValue('phone')
      phone = phone && phone.replace(/\s+/g,"");
      ajax.post('/auth/reset', {...values, ...{phone: phone}}).then((res: any) => {
          if(res && res.status === 200) {
            setactiveKey('2')
          }
      })
  };
  const onSetPassword = (data:any) => {
      if(data.confirmPassword !== data.password) {
          formPassword.resetFields();
        message.error('两次密码不一致')
        return
      }
      ajax.put('/auth/resetPassword', {...data, ...{phone: phone}}).then((res: any) => {
          if(res && res.status === 200) {
              message.success('密码重置成功')
              props.history.push('/user/login')
          }
      })
  }

  return (
    <UserLayout>
      {/*<Row>*/}
        {/*<Col span={8} offset={8}>*/}
          <div className={'layoutLogin'}>
            <Tabs activeKey={activeKey}
                  className={'userTabs'}
                  // renderTabBar={tabBar}
            >
              <TabPane tab="验证手机号" key="1" >
                <Form name="basic"
                  form={formLogin}
                  onFinish={onFinish}
                >
                  <Form.Item
                    wrapperCol={{span: 24}}
                    name="phone"
                    rules={[{ required: true, message: '请输入账户（手机号码）!' }, {len: 11, message: '手机号格式有误'}]}
                  >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="账户（手机号码）" />
                  </Form.Item>
                  <Form.Item
                      wrapperCol={{span: 24}}
                      name="testCode"
                      rules={[{ required: true, message: '请输入验证码!' }]}
                  >
                      <p><Input type="text" prefix={<MessageOutlined className="site-form-item-icon" /> }
                                style={{width: '65%'}}
                                className="code" placeholder="请输入验证码" />
                          <Button type="primary" style={{width: '35%'}} disabled={msgdisabled}
                                  onClick={getMsg}>{msgText}</Button></p>
                  </Form.Item>

                  <Form.Item {...tailLayout} className='btnForm'>
                    <Button type="primary" htmlType="submit" className='loginBtn'>
                      确定
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>
              <TabPane tab="重置密码" key="2" disabled={true}>
                <Form
                  name="basic"
                  form={formPassword}
                  onFinish={onSetPassword}
                >
                  <Form.Item
                    wrapperCol={{span: 24}}
                    name="password"
                    rules={[{ required: true, message: '请输入新密码!' },
                        {pattern: /^[a-zA-Z0-9]{3,}$/, message: '必须包含大写字母、小写字母、数字'}]}
                  >
                    <Input prefix={<LockOutlined className="site-form-item-icon" />} placeholder="请输入新密码" />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{span: 24}}
                    name="confirmPassword"
                    rules={[{ required: true, message: '请确认新密码!' }]}
                  >
                    <Input prefix={ <LockOutlined className="site-form-item-icon" />}
                      placeholder="请确认新密码"
                    />
                  </Form.Item>

                  <Form.Item {...tailLayout} className='btnForm'>
                    <Button type="primary" htmlType="submit" className='loginBtn'>
                      确定
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </div>
        {/*</Col>*/}
      {/*</Row>*/}

    </UserLayout>
  )
}

const mapStateProps = (state: any) => {
  return {
    // loginInfo: state.user.login,
    // registerInfo: state.user.register,
    // username:state.index.username,
    // password:state.index.password
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    userLogin: (data: any) => {
      // console.log(data)
      dispatch(api_index(data)('login'))
    },
      get_public: () => {
          dispatch(public_info())
      },
  }
};

export default connect(mapStateProps, mapDispatchToProps)(Index)
