import React from 'react';

function Index() {

  return (
    <div>
      注册
    </div>
  )
}

export default Index;
