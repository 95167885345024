
import React, { useState} from 'react';
import {Dispatch} from "redux";
import {connect} from "react-redux";

import { Form, Input, Button, Result, Modal, message} from 'antd';
import {ajax} from "@/store/midPromise";
import {regs} from "@/statics/js/libs";


interface InterfaceProps {
    visible: boolean,
    publicInfo: { loginAccount: string },
    onCancel: () => void,
    onSuccess: () => void,
}

const PasswordDialog: React.FC<InterfaceProps> = (props: InterfaceProps) => {

    const [step, setStep] = useState('verfiy')
    const [passwordForm] = Form.useForm()
    const finish1 = (data:any) => {
        const ajaxData = {
            ...data,
            loginAccount: props.publicInfo.loginAccount
        }
        // console.log(ajaxData, props.publicInfo)
        ajax.post('/auth/user/update/password', ajaxData).then(res => {
            if(res.status !== 200) return;
            setStep('result')
            message.success('密码修改成功，请重新登录')
            setTimeout(() => props.onSuccess(), 3000)
        })
    }

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 10 },
        form: passwordForm
    };
    return (
        <Modal title="修改密码" visible={props.visible} onOk={() => layout.form.submit()} width={700}
               onCancel={props.onCancel}>
            <div className="password_page">
                {
                    step === 'verfiy' &&
                    <div>
                        <p>定期更换密码可以让你的账户更加安全</p>
                        <p>为了保证账户安全，建议密码采用字母和数字组合</p>
                        <Form {...layout} name="basic" labelAlign={'left'}
                              onFinish={finish1}
                              onFinishFailed={() => {}}
                        >
                            <Form.Item label="登录账号" name="loginAccount">
                                <div>{props.publicInfo.loginAccount}</div>
                            </Form.Item>

                            <Form.Item label="旧密码" name="oldPassword" rules={[{ required: true, message: '请输入旧密码!' }]}>
                                <Input placeholder={'请输入旧密码'} />
                            </Form.Item>
                            <Form.Item label="新密码" name="newPassword" rules={[
                                { required: true, message: '请输入新密码!' },
                                { pattern: regs.password, message: '密码格式有误，至少包含数字，大小写字母！'}
                            ]}>
                                <Input placeholder={'请输入新密码'} />
                            </Form.Item>
                            <Form.Item label="确认新密码" name="confirm_password" rules={[
                                { required: true, message: '请再次输入新密码!' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('新密码输入不一致!'));
                                    },
                                })
                            ]}>
                                <Input placeholder={'请再次输入新密码'} />
                            </Form.Item>
                        </Form>
                    </div>
                }
                {
                    step === 'result' &&
                    <Result status="success"
                            title="修改成功"
                            subTitle=""
                            extra={[
                                <Button type="primary" key="console">
                                    重新登录
                                </Button>
                            ]}
                    />
                }
            </div>
        </Modal>

    );
}

const mapStateProps = (state: any) => {
    return {
        publicInfo: state.golbal.publicInfo
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(PasswordDialog)
