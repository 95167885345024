import React, {useEffect, useState, useCallback} from "react";
import {connect} from 'react-redux';
import {
    TreeSelect, Card, Checkbox, Row, Col
} from 'antd';
import {
    SearchOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';

import {api_index} from '@/store/model/reducers/index'
import {Dispatch} from 'redux';

import './adminManagement.less'

interface Interfaceprops {
    api_all: (name:string, data: API.InterfaceData) => void,
    list: any[],
    onChange?: (data: any) => void,
}
interface ListROLE{
    id: number,
    name: string,
    all: boolean,
    checked: false,
    children: any[]
}
const Admin: React.FC<Interfaceprops> = (props) => {
    const [value, setvalue] = useState<any[]>([])
    const [showList, setShowList] = useState<ListROLE[]>([])

    const menuData = JSON.parse(localStorage.getItem('menuList')|| '{}')
    let listRole:Array<ListROLE> =  menuData.menuList;

    // 存储 所有的 权限 集合， key 为 id， value 为 数组
    let valueAllobj:any = {}
    const mapList = (data: Array<ListROLE>) => {
        data.forEach(item => {
            if (item.children) {
                valueAllobj[item.id] = []
                item.children.forEach(it => {
                    valueAllobj[item.id].push(it.id)
                })
            }
        })
    }
    mapList(listRole)
    console.log(valueAllobj)

    const changeChecked = useCallback((data, e) => {
        console.log('selected', data, e);
        let arr:any[] = []
        if(e.target.checked){
            arr = [...value].indexOf(data) >= 0 ? [...value]: [...value, data]
        } else {
            arr = [...value].map(item => item !== data ? item: null).filter(item => item)
        }
        setvalue(arr)
        changeProps(arr)
    }, [value])

    const onCheckAllChange = useCallback((e: any, id: string, index:number) => {
        const checked = e.target.checked
        let list:any[]
        if(checked) {
            list = value.concat(valueAllobj[id])
            setvalue(list)
        } else {
            list = value.filter(key => !valueAllobj[id].includes(key))
            setvalue(list)
        }
        let newShowList = [...showList]
        newShowList[index]['checked'] = checked
        setShowList(newShowList)
        console.log(list)
        changeProps(list)
        // console.log(newShowList)
    }, [value, showList])

    const changeProps = (arr: any[]) => props.onChange&&props.onChange(arr)

    useEffect(() => {
        console.log(props.list)
        setvalue(props.list)
        setShowList(listRole)
    }, [])

    return (
        <div className="baseApp-role">
            <div className="title">基础应用权限</div>
            <div className="box">
                {
                    showList.map((item, index) => <Card title={item.name} size="small"
                                                 extra={ <Checkbox onChange={(e) => onCheckAllChange(e, String(item.id), index)}
                                                                   className={String(item.id)}
                                                                   checked={item.checked}>
                                                     全选
                                                 </Checkbox>}
                                                 key={item.id} style={{marginBottom: 20}}>
                        <Checkbox.Group style={{ width: '100%' }} value={value} >
                            {
                                item.children&&item.children.map((it) => <Row key={it.id} style={{marginBottom: 10}}>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={6} offset={1}>
                                                <span>{it.name}</span>
                                            </Col>
                                            <Col span={6} offset={3}>
                                                <Checkbox value={it.id} onChange={(checkedValue) => changeChecked(it.id,  checkedValue)}>全部管理权限</Checkbox>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>)
                            }
                        </Checkbox.Group>
                    </Card>)
                }
            </div>

        </div>
    )
}

const mapStateProps = (state: any) => {
    return {
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        api_all: (name:string, data: API.InterfaceData, ) => {
            dispatch(api_index({...data})(name))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Admin);
