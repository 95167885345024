import React from 'react';

import RouterBox from '../components/routerFn'
import {RouteComponentProps} from "react-router"

interface Interfaceprops extends RouteComponentProps{
    routes: any
}

export const App:React.FC<Interfaceprops> = (props) => {
  const {routes} = props;
    return (
    <div className="c_flex c_flex_col" style={{height: '100%'}}>
      <RouterBox routers={routes} />
    </div>
  );
};
