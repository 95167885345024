// 部门树形选择组件 后期根据需求修改
import React, {useEffect, useState} from "react";

import {
    TreeSelect
} from 'antd';

import {
    get_depart_list
} from '@/store/model/reducers/golbal'

import {connect} from 'react-redux';

import {Dispatch} from 'redux';

interface Interfaceprops {
    value?: number | number[],
    type?: 'check',
    placeholder?: string,
    onChange: (data: any) => void,
    disabled?: boolean,
    multiple?: boolean,
    depart_list: any[],
    get_depart_list: (data?: any) => void,
    perDepartName?: string,
}

const TreeDepart = (props: Interfaceprops) => {
    const [resData, setResData] = useState([])

    const { SHOW_PARENT } = TreeSelect;
    // @ts-ignore
    const onChange = (info) => {
        props.onChange(info)
    }

    const mapResData = (list:any) => {
        return list.map((item:any) => {
            if(item.children) {
                return {
                    ...item,
                    title: item.deptName,
                    value: item.id,
                    key: item.id,
                    children: mapResData(item.children)
                }
            } else {
                return {
                    ...item,
                    title: item.deptName,
                    value: item.id,
                    key: item.id
                }
            }
        })
    }

    const checkID = (id: number, list: any[]) => {
        let flag = false;
        const mp = (arr: any[]) => {
            arr.forEach(item => {
                if(item.children) mp(item.children);
                if(item.id === id) flag = true
            })
        }
        mp(list)
        return flag
    }
    useEffect(() => {
        setResData( mapResData(props.depart_list) )
        // if(props.value !== 0 && ) {
        //     console.log(props.perDepartName)
        //     props.onChange(props.perDepartName)
        // }
    }, [props.depart_list])
    useEffect(() => {
        props.get_depart_list()
    }, [])
    const checkType = {treeCheckable: true,}
    const defaultProps = {
        treeData: resData,
        onChange: onChange,
        value: props.value === 0 ? '' : typeof props.value === 'number' && !checkID(props.value, props.depart_list)? props.perDepartName: props.value,
        disabled: props.disabled || props.value === 0 ? true: false,
        multiple: props.multiple || false,
        placeholder: props.placeholder || '请选择',
        treeNodeFilterProp: 'title',
        showCheckedStrategy: SHOW_PARENT,
        treeDefaultExpandAll: true,
        showSearch: true,
        style: {width: '100%'}
    };
    const tProps = props.type === 'check'? {...defaultProps, ...checkType}: defaultProps
    return (
        <TreeSelect {...tProps} />
    )
}


const mapStateProps = (state: any) => {
    return {
        depart_list: state.golbal.depart_list
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_depart_list: (data?: any) => dispatch(get_depart_list(data))
    }
};


export const TreeDepartment = connect(mapStateProps, mapDispatchToProps)(TreeDepart);
