import React, {
    useEffect, useState
    // , useCallback
} from 'react';
import { withRouter } from "react-router-dom";
import {  Menu } from 'antd';
import {connect} from 'react-redux';
import { Dispatch } from "redux";
import { changeMenuInfo } from "../store/model/reducers/golbal";

import {RouteComponentProps} from "react-router";

interface InterfaceMenu {
  openKey:  string[],
  selKey: string[],
}

interface MenuProps extends RouteComponentProps {
    routesArr: any, // 渲染数组
    menuInfo: any, // redux 当前 页面信息
}

const routerObjMock = {}

// 头部和右侧调用此组件
// props.routesArr
function App(props: any) {
    const [subMenu, setSubMenu] = useState()

    const getItem = (arr: any, fn?: () => void ) => {
        if(!arr) return null;
        const tem = arr.map((item: any) => {
            if(item.hidden) return null
            if(item.routes && item.routes.length>=1 && item.icon) {
                const key = item.path.replace(/\//g,"")
                routerObjMock[key] = item.routes;
            }
            if(!item.icon && item.routes) return getItem(item.routes);
            if(item.redirect) return null
            return <Menu.Item key={item.path} title={item.path}>{item.icon}{item.title}</Menu.Item>
        })
        return tem
    }

    // if(!props.subFlag) console.log(props.routesArr, props.subFlag)
    const headerMenu = getItem(props.routesArr)

    useEffect(() => {
      if(props.location && props.change_menu_info) {
          const pathname = props.location.pathname
          props.change_menu_info({...props.menuInfo, ...{selKey: pathname}})
      }
        const pathname = props.location.pathname.split('/')
        const routerKey = pathname[1] + pathname[2]
        const tem2 = props.subFlag && JSON.stringify(routerObjMock) !== '{}' && getItem(routerObjMock[routerKey])

        setSubMenu(tem2)
    }, [props.location.pathname])

    const clickNav = (item: any) => {
        // console.log(item)
      props.history.push(item.key)
    }

    const clickSub = (item: any) => {
        props.change_menu_info({...props.menuInfo})
    }

    const getHeaderKey = (path: string) => {
        if(!path) return '';
        const arr = path.split('/')
        let result  = ''
        if(arr[1] === 'pass') {
            result = path? path.split('/').slice(0, 3).join('/'): ''
        } else {
            result = '/' + arr[1]
        }
        // if(!props.subFlag) console.log(arr, result)
        return result
        // console.log(path? path.split('/').slice(0, 3).join('/'): '')
    }

    return (
        <Menu
            selectedKeys={props.subFlag ? props.menuInfo.selKey : getHeaderKey(props.menuInfo.selKey)}
            mode={props.mode || 'vertical'}
            theme="light"
            onClick={clickNav}
            onOpenChange={clickSub}
            style={props.style}
        >
            {
                props.subFlag ? subMenu : headerMenu
            }
        </Menu>
    );
}

const mapStateProps = (state: any) => {
  return {
    menuInfo: state.golbal.menuInfo
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    change_menu_info: (data: InterfaceMenu ) => {
      dispatch(changeMenuInfo(data))
    }
  }
};

export default connect(mapStateProps, mapDispatchToProps )(withRouter(App));
