import React, {
    // useEffect, useState
} from "react";

import {DatePicker} from 'antd';
import {Modal, message} from 'antd';
import {TreeDepartment} from "@/components/select";
import {ajax} from "@/store/midPromise";
import moment from 'moment';

interface Interfaceprops {
    flag: boolean,
    onSucc: (result: any) => void,
    title: string,
    data: any,
    onClose: () => void
}

const Detail = (props:Interfaceprops) => {
    let fireTime:any = ''
    let selectData:any=''

    const modalOk = () => {
        if(props.title === '离职'){
            if(fireTime === '') {
                message.error('请选择离职日期')
                return;
            }
            ajax.post('/auth/employee/leave', {
                idList: [props.data.id],
                leaveTime: fireTime
            }).then(res=> {
                if (res.status !== 200) return;
                message.success('操作成功！')
                props.onSucc({})
            })
        }
        if(props.title === '复职'){
            if(selectData === '') {
                message.error('请选择所属部门')
                return;
            }
            ajax.post('/auth/employee/resume', {
                id: props.data.id,
                deptId: selectData
            }).then(res=> {
                if (res.status !== 200) return;
                message.success('操作成功！')
                props.onSucc({})
            })
        }
        if(props.title === '启用'){
            if(selectData === '') {
                message.error('请选择所属部门')
                return;
            }
            ajax.post('/auth/employee/using', {
                id: props.data.id,
                deptId: selectData
            }).then(res=> {
                if (res.status !== 200) return;
                message.success('操作成功！')
                props.onSucc({})
            })
        }
    }

    const modalCancel= () => {
        props.onClose()
    }

    return (
        <Modal
            title={`确认要对该人员账户进行【${props.title}】操作吗？`}
            visible={props.flag}
            onOk={modalOk}
            onCancel={modalCancel}
        >
            <div>
                {
                    props.title === '离职' &&
                    <div className={'sss'} style={{margin: '15px 0px'}}>
                        请指定人员{props.title}日期：
                        <DatePicker onChange={(value) => fireTime = moment(value).format("YYYY-MM-DD") } />
                    </div>
                }
                {
                    props.title === '复职' &&
                    <>
                        <div className='c_mb_5'>请指定人员所属部门：</div>
                        <TreeDepartment onChange={(value) => selectData = value} />
                    </>
                }
                {
                    props.title === '启用' &&
                    <>
                        <div className='c_mb_5'>请指定人员所属部门：</div>
                        <TreeDepartment  onChange={(value) => selectData = value} />
                    </>
                }
            </div>
        </Modal>
    );
}

export default Detail;
