// 导入组件 后期根据需求修改

import React, {useEffect, useState, useCallback} from "react";

import {
    Button,
    Modal,
    Upload
} from 'antd';

import {
    UploadOutlined,
    DownloadOutlined,
    ExclamationCircleTwoTone,
    CheckCircleTwoTone
} from '@ant-design/icons';

import {ajax} from "@/store/midPromise";

const { Dragger } = Upload;

interface Interfaceprops {
    onClose: (flag: Boolean) => void,
    onSuccess: () => void,
    title: string,
    tip1?: any,
    temUrl: string,
    uploadUrl: string,
}
interface InterfaceImportInfo {
    successNum: number,
    errorNum: number,
    token: string
}

const Box:React.FC<Interfaceprops> = (props) => {
    const [flag, setflag] = useState(true)
    const [fileList, setfileList] = useState<Array<any>>([])
    const [importInfo, setimportInfo] = useState<InterfaceImportInfo>({
        successNum: 0,
        errorNum: 0,
        token: ''
    })
    const [exportFileLoading, setExportFileLoading] = useState(false)

    const downloadTem = () => {
        ajax.get(props.temUrl).then(res => {
            window.open(res.data)
        })
    }
    const importsOk = () => {
        props.onClose(false)
    }
    const importsCancel = () => {
        setflag(false)
        setTimeout(() => {
            props.onClose(flag)
        }, 200)
    }

    // @ts-ignore
    const handleChange = (info) => {
        const { file, onSuccess } = info;
        setTimeout(() => {
            onSuccess(file);
            setfileList([file]);
        }, 200)
    }

    const downloadError = () => {
        ajax.get('/auth/employee/download', {params: {token: importInfo['token']}}).then(res => {
            if (res.status !== 200) return;
            window.open(res.data)
        });
    }
    const importFile = useCallback(() => {
        const formData = new FormData()
        formData.append('file', fileList[0])
        ajax.post(props.uploadUrl, formData).then(res => {
            if(res.status === 200) {
                setimportInfo(res.data)
                if(res.data.successCount) {
                    props.onSuccess()
                }
            }
        })
    }, [fileList])

    const uploads = {
        name: 'file',
        fileList: fileList,
        disabled: fileList.length > 0,
        onRemove: () => {
            setfileList([]);
        }
    }

    useEffect(() => {

    }, [])

    return (
        <Modal
            title={props.title}
            visible={flag}
            onOk={importsOk}
            onCancel={importsCancel}
        >
            <div className={'importFiles'}>
                <div className='c_mb_20'>
                    <p>1.下载文件</p>
                    <Button onClick={downloadTem} type={'link'}>点击下载标准模板</Button>
                    {  props.tip1 &&
                        <p className="c_col_text_secondary px12 c_mt_5">{props.tip1}</p>
                    }
                </div>
                <div className='c_mb_20'>
                    <p>2.上传文件</p>
                    <Dragger {...uploads} customRequest={handleChange} >
                        <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                        </p>
                        <p className="ant-upload-text">点击或拖拽进行上传</p>
                    </Dragger>
                </div>
                <div>
                    <p>3.导入文件</p>
                    <Button type='primary' disabled={fileList.length>0? false: true} onClick={importFile}>导入文件</Button>
                </div>

                {
                    importInfo.successNum >0 && fileList.length > 0 &&
                    <div className='c_mt_10'>
                        <CheckCircleTwoTone twoToneColor="#52c41a" /> <span className='c_mr_5'></span>成功导入了{importInfo.successNum}个人员信息
                    </div>
                }
                {
                    importInfo.errorNum >0 && fileList.length > 0 &&
                    <>
                        <div className='c_mt_10'>
                            <ExclamationCircleTwoTone twoToneColor="#eb2f96" /> <span className='c_mr_5'></span> {importInfo.errorNum}个人员未完成导入，请下载这部分信息进行修改并重新导入。
                        </div>
                        <Button className='c_mt_10' type='link' icon={<DownloadOutlined />} size={'small'}
                                onClick={downloadError} disabled={exportFileLoading}>点击下载错误信息{exportFileLoading&& '正在下载请稍后...'}</Button>
                    </>
                }

            </div>
        </Modal>
    )
}

export default Box;
