import React, {
    // useEffect, useState
} from "react";import {connect} from 'react-redux';
import {
    Result
} from 'antd';

import './index.less';

import {Dispatch} from 'redux';

import {RouteComponentProps} from "react-router"


interface Interfaceprops extends RouteComponentProps{

}

const Product:React.FC<Interfaceprops> = (props) => {

    return <div>
        <div className="main-content">
            <Result
                status="404"
                title="页面不存在"
                subTitle="功能正待开发..."
                extra={''}
            />
        </div>
    </div>
}

const mapStateProps = (state: any) => {
    return {

    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(Product);
