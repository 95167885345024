// 组织负责人选择组件 后期根据需求修改

import React, {useEffect} from "react";

import {
    Input,
} from 'antd';

import {InputProps} from 'antd/lib/input'


// declare module 'propsAdd' {
//
// }

// interface InputProps {
//     // value?: string | string[],
//     type?: 'check',
//     // placeholder?: string,
//     onChange: (data: any) => void
// }

const InputCom = (props: InputProps) => {
    let oldValue:any
    // @ts-ignore
    const onChange = (info) => {
        console.log(info)
        console.log(oldValue)
        props.onChange && props.onChange(info)
    }

    useEffect(() => {
        oldValue = props.value
    }, [props.value])

    const defaultProps = {
        onChange: onChange,
        style: {width: '100%'},
        ...props
    };

    return (
        <Input {...defaultProps} />
    )
}


export default InputCom;
