import React, {useEffect,
    // useState
} from "react";

import {Button, Drawer, Form, Input, Radio, message} from 'antd';
import {TreeDepartment} from "@/components/select";
import {sexInter} from "@/statics/js/config/interface";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {api_index} from "@/store/model/reducers/index";
import {ajax} from "@/store/midPromise";
import {regs} from "@/statics/js/libs";


interface Interfaceprops {
    title: string,
    type: string,
    flag: boolean,
    onChangeValue: (flag: boolean) => void,
    onSucc: (result: any) => void,
    sexArr: [sexInter],
    valueData?: any
}

const validateMessages = {};

const Detail = (props:Interfaceprops) => {

    const [formObj] = Form.useForm()

    const modalCancel= () => {
        setTimeout(() => {
            props.onChangeValue(false)
        }, 200)
    }
    const add = (data:any) => {
        ajax.post('/auth/employee/add', data).then(res => {
            if(res.status !== 200) return;
            message.success('添加成功')
            props.onSucc(data)
        })
    }
    const edit = (data:any) => {
        ajax.post('/auth/employee/update', {
            ...data,
            id: props.valueData.id
        }).then(res => {
            if(res.status !== 200) return;
            message.success('编辑成功')
            props.onSucc(data)
        })
    }

    const onFinishAdd = (data:any) => {
        console.log(data)
        if(props.title === '新增人员') {
            add(data)
        }
        if(props.type === 'edit'){
            edit(data)
        }
            // setdepartArr(data)
    }

    useEffect(() => {
        console.log(props.valueData)
        const obj = {
            ...props.valueData,
            deptId: props.valueData.directlyDepartmentId,
            deptIdList: props.valueData.departmentIds
        }
        formObj.setFieldsValue(obj)
    }, [props.flag])


    return (
        <Drawer
            title={props.title}
            placement="right"
            width={400}
            onClose={modalCancel}
            visible={props.flag}
        >
            <Form
                name="basic"
                form={formObj}
                validateMessages={validateMessages}
                onFinish={onFinishAdd}
                onFinishFailed={() => {}}
            >
                <Form.Item
                    label="姓名"
                    name="employeeName"
                    rules={[
                        { required: true, message: '请填写姓名'}
                    ]}
                >
                    <Input placeholder='请填写' style={{width: '250px'}} />
                </Form.Item>
                <Form.Item
                    label="性别"
                    name="sex"
                    rules={[
                        { required: true, message: '请选择性别'}
                    ]}
                >
                    <Radio.Group >
                        {
                            props.sexArr.map((item) => <Radio value={item.value} key={item.value} >{item.name}</Radio>)
                        }
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label="手机号码"
                    name="mobile"
                    rules={[
                        { required: true, message: '请填写手机号码'},
                        { pattern: regs.phone, message: '手机号码格式有误'}
                    ]}
                >
                    <Input placeholder='请填写' maxLength={11} style={{width: '250px'}} />
                </Form.Item>
                <Form.Item
                    label="身份证号码"
                    name="idNumber"
                    rules={[
                        { required: true, message: '请填写身份证号码'},
                        { pattern: regs.ids, message: '身份证号码格式有误'}
                    ]}
                >
                    <Input placeholder='请填写' style={{width: '250px'}} />
                </Form.Item>
                <Form.Item
                    label="直属部门"
                    name="deptId"
                    rules={[
                        { required: true, message: '请选择直属部门'}
                    ]}
                >
                    <TreeDepartment
                        // value={departArr}
                        onChange={() => {} } />
                </Form.Item>
                {/*{*/}
                {/*    props.title === '新增人员' &&*/}
                {/*    <Form.Item*/}
                {/*        label="直属上级"*/}
                {/*        name="deptIdName"*/}
                {/*    >*/}
                {/*        <div>{'ss'}</div>*/}
                {/*    </Form.Item>*/}
                {/*}*/}
                {
                    props.title === '编辑人员' &&
                    <Form.Item
                        label="多部门"
                        name="deptIdList"
                    >
                        <TreeDepartment multiple={true}
                                        // value={departArr}
                                        onChange={() => {} } />
                    </Form.Item>
                }

                <Form.Item>
                    <Button type="primary" htmlType="submit">提交</Button>
                </Form.Item>

            </Form>
        </Drawer>
    );
}


const mapStateProps = (state: any) => {
    return {
        member_list: state.index.member_list,
        sexArr: state.golbal.arrConfig.sex

    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_member_list: (data: any) => {
            //这个是获取所有人员数据
            dispatch(api_index(data)('getMemberList'))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Detail);
