import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import {Provider} from 'react-redux'
import { ConfigProvider } from 'antd';

import './statics/css/index.less';
import store from "@/store";

import getRouter from "./router";
import * as GLOBAL_LIBS from '@/statics/js/libs'

import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('en');
const windowObj = global.windows || window;
windowObj.GLOBAL_LIBS = GLOBAL_LIBS


ReactDOM.render(
    <Provider store={store}>
        {/*<PersistGate loading={null} persistor={persistor}>*/}
            <ConfigProvider locale={zhCN} >
                <Router basename={''}>
                    {getRouter()}
                </Router>
            </ConfigProvider>
        {/*</PersistGate>*/}
        {/*<div id='subapp-container'></div>*/}
    </Provider>
,
  document.getElementById('root')
);
