import {combineReducers} from "redux";

import golbal from './reducers/golbal'
import index from './reducers/index'

// import user from './reducers/user'
// import member from './reducers/member'

export default combineReducers({
    index,
    golbal,
    // user,
    // member,
})
