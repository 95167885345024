// 组织负责人选择 组件 后期根据需求修改

import React, {
    // useEffect, useState
} from "react";

import {
    Select,
} from 'antd';

import {connect} from 'react-redux';

import {Dispatch} from 'redux';
import {ajax} from "@/store/midPromise";

interface Interfaceprops {
    value: string,
    type?: 'check',
    placeholder?: string,
    onChange: (data: any) => void,
    style?: any,
    name?: string,
}

const { Option } = Select;

let timeout:any;
let currentValue: string;
function fetch(value: string, callback: (data: Array<any>) => void) {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    function fake() {
        ajax(`/auth/employee/manager/v2`, {params: {
                searchName: value,
                deptId: ''
            }})
            .then(d => {
                if(d.status !== 200) return;
                if(currentValue === value) {
                    callback(d.data);
                }
            });
    }
    timeout = setTimeout(fake, 300);
}

interface MyState {
    data: Array<{
        userId: any,
        employeeName: string
    }>,
    value: string
}
class TreeSelectMemb1 extends React.Component<Interfaceprops, MyState>{
    constructor(props: Interfaceprops) {
        super(props);
        this.state = {
            data: [],
            value: '',
        }
    }

    handleSearch = (value: string) => {
        // if (value) {
        //     fetch(value, data => this.setState({ data }));
        // } else {
        //     this.setState({ data: [] });
        // }
    };

    handleChange = (value: string) => {
        this.setState({ value });
        this.props.onChange(value)
    };

    checkID = (userid: any, list: any[]) => {
        let flag = false
        list.forEach(item => {
            if(String(item.id) === String(userid)) flag = true
        })
        return flag
    }

    componentDidMount () {
        console.log(this.props.value)
        fetch('', data => {
            const flag = this.checkID(this.props.value, data)
            const val = flag ? this.props.value : (this.props.name || '')
            this.setState({ data, value: val })
        });
    }

    render() {
        const options = this.state.data.map(d => <Option key={d.userId} value={d.userId}>{d.employeeName}</Option>);
        return (
            <Select
                showSearch
                value={this.state.value}
                placeholder={this.props.placeholder}
                style={this.props.style || { width: '100%' } }
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                filterOption={(input, option) => {
                    console.log(option)
                    // @ts-ignore
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                } }
            >
                {options}
            </Select>
        );
    }
}

const mapStates = (state: any) => {
    return {

    }
}
const mapActions = (dispatch: Dispatch) => {
    return {

    }
};


export const TreeSelectMember =  connect(mapStates, mapActions)(TreeSelectMemb1);
