import React, {useEffect, useState, useCallback} from "react";
import {connect} from 'react-redux';
import {
    Select, Tree
} from 'antd';
import {} from '@ant-design/icons';

import {Dispatch} from 'redux';
import {ajax} from "@/store/midPromise";
import {get_depart_list} from "@/store/model/reducers/golbal";


interface Interfaceprops {
    onSelect?: (value: any) => void,
    onChecked?: (value: any) => void,
    get_depart_list: () => void,
    depart_list: any
}

const Admin: React.FC<Interfaceprops> = (props) => {
    const [resData, setResData] = useState([])
    const onSelect = useCallback((selectedKeys: React.Key[], info: any) => {
        // console.log('selected', selectedKeys, info);
        props.onSelect && props.onSelect(selectedKeys[0])
    }, [])

    const onCheck = useCallback((checkedKeys: any, info: any) => {
        console.log('onCheck', checkedKeys, info);
    }, [])
    const mapResData = (list:any) => {
        return list.map((item:any) => {
            if(item.children) {
                return {
                    ...item,
                    title: item.deptName,
                    value: item.id,
                    key: item.id,
                    children: mapResData(item.children)
                }
            } else {
                return {
                    ...item,
                    title: item.deptName,
                    value: item.id,
                    key: item.id
                }
            }
        })
    }
     useEffect(() => {
         setResData( mapResData(props.depart_list) )
     }, [props.depart_list])
    useEffect(() => {
        props.get_depart_list()
    }, [])
    return (
        <div className={'c_pad_t_10'}>
            {
                resData.length > 0 &&
                <Tree defaultExpandAll={true}
                      onSelect={onSelect}
                      onCheck={onCheck}
                      treeData={resData}
                />
            }
        </div>
    )
}

const mapStateProps = (state: any) => {
    return {
        depart_list: state.golbal.depart_list
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_depart_list: () => {
            dispatch(get_depart_list())
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Admin);
