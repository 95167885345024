import React, {useEffect} from 'react';

import RouterBox from '../components/routerFn'

import Header from '../components/header/index'
import Menulayout from '../components/menu'

import './less/mainApp.less'
import {RouteComponentProps} from "react-router"
import {Dispatch} from "redux";
import {customer_info, public_info} from "@/store/model/reducers/golbal";
import {connect} from "react-redux";

interface Interfaceprops extends RouteComponentProps {
    routes: any,
    get_public: () => void,
    get_customer_info: () => void,
}

const MainApp:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;
    const APINAME = windowObj.localStorage.getItem('apiName')
    const {routes} = props;

    // const obj = {
    //     title: '',
    //     routers: []
    // }
    // const com = (arr: any, path: string) => {
    //       for (const element of arr){
    //           if (element.path === path) {
    //               console.log(element)
    //               obj.title = element.title
    //               obj.routers = element.routes
    //           }
    //           if(element.routes) com(element.routes, path)
    //       }
    //       return obj
    // }
    // const [title, setTitle] = useState('')

  useEffect(() => {
      // console.log(com(routes, props.location.pathname))
      //   setTitle(com(routes, props.location.pathname))
      props.get_public()
  }, [])

  return (
      <div className={'c_flex'} style={{height: '100%'}}>
          <div id='page_content' style={{width: '100%',overflow: 'auto',boxSizing: 'border-box', position: 'absolute',height: '100%', paddingTop: 70}}>
              {/*/!*子应用挂载位置*!/*/}
              {/*<div id='subapp-container'></div>*/}

              {/*子菜单栏和内容*/}
              <Menulayout routesArr={routes} subFlag={true} style={{width: '200px', height: '100%', zIndex: 1, position: 'fixed'}} />
              <div style={{margin: '30px', flexGrow: 1, paddingLeft: 200}}>
                  <RouterBox routers={routes} mainRedirect={true} />
              </div>
          </div>
      </div>
  );
}

const mapStateProps = (state: any) => {
    return {
        // registerInfo: state.user.register,
        // username:state.index.username,
        // password:state.index.password
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_public: () => {
            dispatch(public_info())
        },
        get_customer_info: () => {
            dispatch(customer_info())
        },
    }
};

export default connect(mapStateProps, mapDispatchToProps)(MainApp)
