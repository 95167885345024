import React, {useEffect} from "react";
import { Modal } from "antd";
import SelectList from "@/components/select/select-list"
import {ajax} from "@/store/midPromise";

interface InterfaceProps {
    onClose: () => void,
    flag: boolean,
    onSuccess: () => void
}
const Company = (props: InterfaceProps) => {

    const companyList = JSON.parse(localStorage.getItem('companyList') || '[]')
    // console.log(companyList)
    const companyList2 = companyList.map((item: any) => {
        item.id = item.company.id
        item.name = item.company.companyName
        return item
    })
    // console.log(companyList2)
    const onSelect = (item:any) => {
        ajax.post('/auth/runAs', {companyId: item.id}).then((res: any) => {
            if(res && res.status === 200) {
                props.onSuccess()
                props.onClose()
            }
        })
    }
    const onClose = () => {
        props.onClose()
    }

    useEffect(() => {

    }, [props.flag])

    return (
        <React.Fragment>
            <Modal
                title="选择公司"
                visible={props.flag}
                footer={null}
                onCancel={onClose}
                width={350}
            >
                <SelectList list={companyList2} onSelect={onSelect} />
            </Modal>
        </React.Fragment>
    );
}

export default Company;
