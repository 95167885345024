import React, {useEffect, useState, useCallback} from "react";
import {
    Button, Checkbox, Form, Input,
    Modal,
    Table,
    message,
    Spin
} from 'antd';
// import {Table} from 'antd'
import {
    TreeDepartment,
    TreeSelectMember,
    TreeSelectOrganizationType
} from "@/components/select";

import {Dispatch} from "redux";
import {api_index} from "@/store/model/reducers/index";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import {ajax} from "@/store/midPromise";

// const { confirm } = Modal;
interface Interfaceprops extends RouteComponentProps{
    get_organization_list: (data: API.InterfaceData) => void,
    del_organization: (data: API.InterfaceData) => void,
    add_organization: (data: API.InterfaceData) => void,
    edit_organization: (data: API.InterfaceData) => void,
}
let activeDepartId = -1, activeParentId = -1;
let name = {member: '', depart: ''}

const Setting:React.FC<Interfaceprops> = (props) => {
    const [tableData, setTableData] = useState([])

    const [setobj, setsetobj] = useState<{flag: boolean, title: string, id?: number}>({flag: false, title: ''})
    const [delFlag, setdelFlag] = useState(-1)

    const [formSet] = Form.useForm();
    const addDapt= (id: any) => {
        setsetobj({flag: true, title: '新增', id: id})
        formSet.setFieldsValue({
            deptParentId: id,
            deptName: '',
            typeId: 2,
            employeeId: ''
        })
    }
    const editDapt= (data: any) => {
        console.log(data)
        const parentId = data.deptParentId
        activeDepartId = data.id
        setsetobj({flag: true, title: '编辑', id: parentId})
        name.member = data.employeeName
        name.depart = data.deptParentName
        formSet.setFieldsValue({
            deptParentId: parentId,
            deptName: data.deptName,
            typeId: data.typeId,
            employeeId: data.employeeId
        })
    }
    const onFinishAdd = (data:any) => {
        console.log(data)
        if(setobj.title === '新增') {
            ajax.post('/auth/dept/add', {...data, ...{
                    deptParentId: data.deptParentId
                }}).then(res => {
                if(res.status !== 200) return;
                message.success('添加成功')
                setsetobj({title: '', flag: false})
                init()
            })
        }
        if(setobj.title === '编辑') {
            ajax.post('/auth/dept/update', {
                ...data,
                ...{id: activeDepartId}
            }).then(res => {
                if(res.status !== 200) return;
                message.success('编辑成功')
                setsetobj({title: '', flag: false})
                init()
            })
        }
    }
    let delChildrenFlag = true
    const delValue = (e: any) => {
        console.log(e, e.target.checked)
        delChildrenFlag  = e.target.checked
    }
    const delOk = () => {
        ajax.post('/auth/dept/delete', {id: delFlag, deptParentId: activeParentId,
            isDeleteChild: delChildrenFlag ? 1: 0}).then(res => {
            if(res.status !== 200) return
            message.success('删除成功')
            setdelFlag(-1)
            init()
        })
    }

    // const mapDepart =(data: any) => {
    //     if(!data) return
    //     return data.map((item: any) => {
    //         const obj = {
    //             title: item.deptName,
    //             value: item.id,
    //             key: item.id,
    //             children: mapDepart(item.children)
    //         }
    //         return obj
    //     })
    // }
    const init = useCallback(() => {
        const mapList = (data: any) => {
            return data.map((item: any) => {
                if(item.deptList.length>0) {
                    mapList(item.deptList)
                    item.children = item.deptList
                }
                return item
            })
        }
        ajax.get('/auth/dept/get').then((res: any) => {
            if(res.status !== 200) return
            // console.log(mapList(res.data))
            setTableData(mapList(res.data))
        })
    }, [])
    useEffect (() => {
        init()
    }, [init])

    const columns = [
        {
            title: '组织名称',
            dataIndex: 'deptName',
            width: 220,
        },
        {
            title: '组织类别',
            dataIndex: 'typeName',
            width: 120,
        },
        {
            title: '在职人数',
            dataIndex: 'employeeCount',
            width: 100,
        },
        {
            title: '组织负责人',
            dataIndex: 'employeeName',
            width: 180,
        },
        {
            title: '操作',
            width: 180,
            key: 'ope',
            render: (data: any) => <>
                <Button type={'link'} size={'small'} onClick={() => editDapt(data)}>编辑</Button>
                <Button type={'link'} size={'small'} onClick={() => addDapt(data.id)}>新增</Button>
                {
                    data.deptParentId !== 0 &&
                    <Button type={'link'} size={'small'} onClick={() => {
                        setdelFlag(data.id); activeParentId = data.deptParentId
                    } }>删除</Button>
                }
            </>
        },
    ];
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };
    return (
        <div className="content-page">
            {
                tableData.length >= 1 ?
                <Table
                    expandable={{defaultExpandAllRows: true}}
                    rowKey={'id'}
                    columns={columns}
                    dataSource={tableData}
                /> :
                    <div className={'c_loading'}>
                        <Spin size="large" />
                    </div>
            }


            <Modal
                title={setobj.title}
                visible={setobj.flag}
                onOk={() => formSet.submit()}
                onCancel={() => setsetobj({flag: false, title: '删除'})}
                destroyOnClose={true}
                maskClosable={false}>
                <Form
                    {...layout}
                    name="basic"
                    form={formSet}
                    validateMessages={{}}
                    onFinish={onFinishAdd}
                    onFinishFailed={() => {}}
                >
                    <Form.Item
                        label="上级组织"
                        name="deptParentId"
                        rules={[{ required: true, message: '请选择上级组织'}]}
                    >
                        <TreeDepartment value={formSet.getFieldValue('deptParentId')} perDepartName={name.depart}
                                        onChange={() => {} } />
                    </Form.Item>
                    <Form.Item
                        label="组织名称"
                        name="deptName"
                        rules={[{ required: true, message: '请填写组织名称'}]}
                    >
                        <Input placeholder='请填写' maxLength={20} />
                    </Form.Item>
                    <Form.Item label="组织类别" name="typeId">
                        <TreeSelectOrganizationType value={formSet.getFieldValue('typeId')} onChange={() => {} } />
                    </Form.Item>
                    <Form.Item
                        label="组织负责人"
                        name="employeeId"
                    >
                        <TreeSelectMember value={formSet.getFieldValue('employeeId')} onChange={() => {} } name={name.member}  />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={'删除组织'}
                visible={delFlag>=0 ? true: false}
                onOk={delOk}
                onCancel={() => setdelFlag(-1)}
                maskClosable={false}
            >
               <div>
                   <div><Checkbox defaultChecked={true} onChange={delValue}>同时删除子组织</Checkbox></div>
                   <div>若勾选同时删除子组织，则其下所有子组织一并删除，组织内人员自动归入其上级组织；</div>
                   <div>若未勾选同时删除子组织，则其下所有子组织及人员将自动归入上级组织。</div>
                   <div>确认要删除吗？</div>
               </div>
            </Modal>
        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        member_list: state.index.member_list,
        arrConfig: state.golbal.arrConfig
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_organization_list: (data: API.InterfaceData) => {
            dispatch(api_index(data)('getOrganization'))
        },
        del_organization: (data: API.InterfaceData) => {
            dispatch(api_index(data)('delOrganization'))
        },
        add_organization: (data: API.InterfaceData) => {
            dispatch(api_index(data)('addOrganization'))
        },
        edit_organization: (data: API.InterfaceData) => {
            dispatch(api_index(data)('editOrganization'))
        },
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Setting);

