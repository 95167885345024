
import React, {useCallback, useState} from 'react';
import {Dispatch} from "redux";
import {connect} from "react-redux";

import {Button} from 'antd';
import {ajax} from "@/store/midPromise";

interface InterfaceProps {
    number?: number,
    phone: number,
    onCancel?: () => void,
    onSuccess?: () => void
}
let timers: NodeJS.Timeout;
const SmsButton: React.FC<InterfaceProps> = (props) => {
    // const windowObj = global.windows || window;
    // console.log(props.phone)
    const timeNum = props.number || 60;
    const [btnTime, setBtnTime] = useState(timeNum + 1);
    const [loading, setloading] = useState(false)
    const getText = useCallback(() => {
        return btnTime > timeNum ? '获取验证码': btnTime > 0 ? `${btnTime}S后再次获取` : '重新获取'
    }, [btnTime, timeNum])

    const getNum = useCallback((number) => {
        timers = setTimeout(() => {
            if(number >= 1) {
                setBtnTime(number - 1)
                getNum(number - 1)
            } else {
                clearTimeout(timers)
            }
        }, 1000)
    }, [])

    const clickBtn = () => {
        setloading(true)
        ajax.get(`/auth/user/get/code?mobile=${props.phone}`).then(res => {
            setloading(false)
            if(res.status !== 200) return;
            setBtnTime(timeNum)
            getNum(timeNum)
        })
    }

    const getStatus = () => {
        const result  = (window as any).GLOBAL_LIBS.regs.phone.test(props.phone) ? 'succ': 'fail'
        const result2 = (btnTime === 0 || btnTime > timeNum) ? 'succ': 'fail'
        // console.log(props.phone, (window as any).GLOBAL_LIBS.regs.phone.test(props.phone))
        return (result === 'succ' && result2 === 'succ') ? false: true
    }

    return <Button type="primary" disabled={getStatus()} loading={loading}
                   onClick={clickBtn}>{getText()}</Button>
}

const mapStateProps = (state: any) => {
    return {
        publicInfo: state.golbal.publicInfo,
        customer_info: state.golbal.customer_info
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(SmsButton)
