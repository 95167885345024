import React, {useEffect} from "react";
import './select-list.less'

interface InterfaceItem {
    id: number,
    name: string
}

interface InterfaceProps {
    onSelect: (item:any) => void,
    list: Array<InterfaceItem>
}

const List = (props: InterfaceProps) => {

    const onSelect = (item: any) => {
        props.onSelect(item)
    }
    useEffect(() => {

    }, [props.list])

    return (
        <React.Fragment>
            <div className="item-wrap" id="itemWrap">
                {
                    props.list.map(item => <div className="item" key={item.id} onClick={() => onSelect(item)}>
                        <span>{item.name}</span>
                    </div>)
                }
            </div>
        </React.Fragment>
    );
}

export default List;
