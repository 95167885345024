import { Route, Switch, Redirect } from "react-router-dom";
import React, {useEffect} from "react";
import * as GLOBAL_LIBS from '@/statics/js/libs'
import {withRouter} from "react-router-dom"


function RouteWithSubRoutes(route: any) {
  return (
    <Route
      key={route.path}
      path={route.path}
      exact={route.exact}
      render={props => {
        return <route.component {...props} routes={route.routes} LIBS={GLOBAL_LIBS} />
      }}
    />
  );
}

const Layout = (props: any) => {
    useEffect(() => {
        if(props.location.pathname === '/') {
            props.history.push('/user/login')
        }
    }, [props.location.pathname])
  return (
    <React.Fragment>
      {props.children}
      <Switch>
        {props.routers && props.routers.map((item: any, i: any) => {
          // @ts-ignore
          return <RouteWithSubRoutes key={i} {...item} />
        })}

          {
              !props.mainRedirt && props.routers && props.routers[0] &&
              <Route path={props.location.path}>
                  <Redirect to={props.routers[0].path} />
              </Route>
          }
      </Switch>
    </React.Fragment>
  );
}

export default withRouter(Layout);
