import React, {useEffect, useState, useCallback} from "react";
import {connect} from 'react-redux';
import {
    Table,
    Input,
    Select,
    Button,
    Modal,
    message,
    DatePicker
} from 'antd';
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';

import './index.less';

import {api_index} from '@/store/model/reducers/index'
import {Dispatch} from 'redux';

import ImportsBox from '@/components/importBox/indexToken'
import {sexInter} from '@/statics/js/config/interface'
import {RouteComponentProps} from "react-router"
import QuitCom from  './component/quit'
import EditMember from './component/editMember'
import {ajax} from "@/store/midPromise";
import moment from 'moment'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';

const {Option} = Select;
const { confirm } = Modal;

interface Interfaceprops extends RouteComponentProps{
    get_public_info: Function,
    get_member_list: Function,
    member_list: any,
    sexArr: [sexInter],
    pageSizeOptions: any[]
}

const arr_title = [
    {
        name: "在职",
        value: 10
    },
    {
        name: "已停用",
        value: 20
    },
    {
        name: "已离职",
        value: 30
    }
    ];


let fireTime:any = ''
let ajaxStatus = -1
let selectIdArr:any[] = []

const MemberList:React.FC<Interfaceprops> = (props) => {
    // console.log(quitRef)

    const [ajaxData, setAjaxData] = useState({searchName: '', page: 1, rows: 10, status: 10})
    const [resData, setresData] = useState({total: '', data: [], loading: true})

    const [selectedRowKeys, setselectedRowKeys] = useState([])
    const [quitFlag, setquitFlag] = useState({flag: false, title: '', data: {}})
    const [putOnoff, setputOnoff] = useState(false)
    const [memberObj, setmemberObj] = useState({flag: false, title: '', type: '', valueData: {}})

    const stateChange = (data: any) => {
        // console.log(data)
        selectIdArr = []
        setAjaxData({...ajaxData, status: data, page: 1})
        ajaxStatus = data
    }

    const onSelectChange = (selectedRowKeys: any) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        selectIdArr = [...selectedRowKeys]
        selectIdArr = Array.from(new Set(selectIdArr))

        setselectedRowKeys(selectedRowKeys);
    };
    const putFiles = () => {
        setputOnoff( true)
    }

    const edit = (data:any) => {
        setmemberObj({flag: true, title: '编辑人员', type: 'edit', valueData: data})
    }
    const close = (data:any) => {
        confirm({
            title: `确认要对该人员【${data.employeeName}】账户进行【停用】吗？`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk() {
                ajax.post('/auth/employee/stop', {idList: [data.id]}).then(res => {
                    if (res.status !== 200) return;
                    message.success('停用成功！')
                    init()
                })
            }
        });
    }
    const fireMember = useCallback((data:any) => {
        console.log(ajaxStatus)
        if(ajaxStatus === 10 || ajaxStatus === -1) { // 在职
            setquitFlag({flag: true, title: '离职', data: data})
        } else {
            confirm({
                title: `确认要对该人员【${data.employeeName}】账户进行【离职】吗？`,
                icon: <ExclamationCircleOutlined />,
                content: '',
                onOk() {
                    ajax.post('/auth/employee/direct/leave', {idList: [data.id]}).then(res => {
                        if (res.status !== 200) return;
                        message.success('离职成功！')
                        init()
                    })
                }
            });
        }
    }, [ajaxStatus])

    const restartMember =(data:any) => {
        setquitFlag({flag: true, title: '启用', data: data})
    }

    const addMember = () => {
        setmemberObj({
            ...memberObj,
            ...{flag: true, title: '新增人员', type: 'add'},
            valueData: {
                employeeName: '',
                sex: 1,
                mobile: '',
                idNumber: '',
                deptId: '',
                deptIdList: []
            }
        })

    }
    const copyMember = (data: any) => {
        setquitFlag({flag: true, title: '复职', data: data})
    }
    const goDetail = (data: any) => {
        console.log(data)
        localStorage.setItem('member_id', data.id)
        props.history.push('/pass/organization/member/detail')
    }
    const cancelMemberDia =() => {
        setmemberObj({...memberObj, flag: false})
    }
    const successEdit = () => {
        cancelMemberDia()
        init()
    }
    const stopMemberBatch = () => {
        confirm({
            title: `确认要对选择人员账户进行【停用】吗？`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                ajax.post('/auth/employee/stop', {
                    idList: selectIdArr
                }).then(res => {
                    if (res.status !== 200) return;
                    message.success('停用成功')
                    init()
                })
            }
        });
    }
    const fireMemberBatch = () => {
        confirm({
            title: `确认要对选择人员账户进行【离职】吗？`,
            icon: <ExclamationCircleOutlined />,
            content: <DatePicker locale={locale} onChange={(value) => fireTime = moment(value).format("YYYY-MM-DD") } />,
            onOk() {
                ajax.post('/auth/employee/leave', {
                    idList: selectIdArr,
                    leaveTime: fireTime
                }).then(res => {
                    if (res.status !== 200) return;
                    message.success('操作成功')
                    init()
                })
            }
        });

    }

    const quitSucc = (data: any) => {
        init();
        closeQuit();
    }
    const columns = [
        {
            title: '姓名',
            width: 100,
            render: (data: any) => <Button type='link' style={{paddingLeft: 0}}
                                           onClick={() => goDetail(data)}>{data.employeeName}</Button>
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            width: 120,
        },
        {
            title: '性别',
            dataIndex: 'sex',
            width: 60,
            render: (data: any) => <span>{Number(data) === 1? '男': Number(data) === 2 ?'女': ''}</span>
        },
        {
            title: '身份证号码',
            dataIndex: 'idNumber',
            width: 120,
        },
        {
            title: '部门',
            dataIndex: 'departmentNames',
            width: 120,
        },
        {
            title: '直属上级',
            dataIndex: 'directlyEmployeeName',
            width: 120,
        },
        {
            title: '操作',
            width: 180,
            fixed: 'right' as 'right',
            render: (data: any) => {
                return (
                    <div>
                        {
                            ajaxData.status === 10 &&
                            [
                                <Button type={'link'} size={'small'} key={'1'} onClick={() => edit(data)}>编辑</Button>,
                                <Button type={'link'} size={'small'} key={'2'} onClick={() => close(data)}>停用</Button>
                            ]
                        }
                        {
                            ajaxData.status === 20 &&
                            <Button type={'link'} size={'small'} onClick={() => restartMember (data)}>启用</Button>
                        }
                        {
                            (ajaxData.status === 10  || ajaxData.status === 20 ) &&
                            <Button type={'link'} size={'small'} onClick={() => fireMember(data)}>离职</Button>
                        }
                        {
                            ajaxData.status === 30  &&
                            <Button type={'link'} size={'small'} onClick={() => copyMember(data) }>复职</Button>
                        }
                    </div>
                )
            }
        },
    ];

    const init = useCallback(() => {
        ajax.post('/auth/employee/manager', ajaxData).then(res => {
            if(res.status !== 200) return
            setresData((r) => { return {...r, ...res, ...{loading: false}} })
        })
    }, [ajaxData])

    useEffect(() => {
        init()
    }, [ajaxData, init])

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        // selections: [
            // Table.SELECTION_ALL,
            // Table.SELECTION_INVERT
        // ],
    };
    const closeQuit = () => {setquitFlag({...quitFlag, flag: false})}
    const tableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: columns,
        dataSource: resData.data,
        rowSelection: rowSelection,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }
    return <div>
        <div className="main-content">
            <div className='c_flex c_flex_rl c_flex_up c_back_fff '>
                <div>
                    <Input.Search style={{width: 200, margin: '0 20px',}}
                                  onSearch={value => setAjaxData({...ajaxData, ...{searchName: value, page: 1}})} enterButton
                                  placeholder="支持姓名手机号搜索"/>
                    <Select style={{width: 120}} value={ajaxData.status} onChange={stateChange}>
                        {
                            arr_title.map((item) => {
                                return <Option key={item.value} value={item.value}>{item.name}</Option>
                            })
                        }
                    </Select>
                </div>
                <div className='button_box'>
                    <Button type="primary" onClick={addMember}>新增人员</Button>
                    <Button type="primary" onClick={putFiles}>导入</Button>
                    <Button type="primary" onClick={stopMemberBatch} disabled={ajaxData.status !== 10}>停用</Button>
                    <Button type="primary" onClick={fireMemberBatch} disabled={ajaxData.status === 30}>离职</Button>
                </div>
            </div>

            <div className="table_box">
                <div className="con-table">
                    <Table {...tableOptions}/>
                </div>
            </div>

            {  // 停用单个
                quitFlag.flag &&
                <QuitCom {...quitFlag}
                         onClose={closeQuit}
                         onSucc={quitSucc} />
            }

            {  // 导入人员
                putOnoff &&
                <ImportsBox title={'导入员工'} temUrl={'/auth/employee/get/template'}
                            onSuccess={() => init()}
                            uploadUrl={'/auth/employee/upload'} onClose={(flag: Boolean) => {setputOnoff( false);}} />
            }

            <EditMember {...memberObj} onChangeValue={(flag:boolean) => {setmemberObj({...memberObj, ...{flag: flag}})}}
                        onSucc={successEdit}  />
        </div>
    </div>
}

const mapStateProps = (state: any) => {
    return {
        member_list: state.index.member_list,
        pageSizeOptions: state.golbal.pageSizeOptions,
        sexArr: state.golbal.arrConfig.sex
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        get_member_list: (data: any) => {
            //这个是获取所有人员数据
            dispatch(api_index(data)('getMemberList'))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(MemberList);
