import React, {useEffect, useState, useCallback} from "react";
import {connect} from 'react-redux';
import {
    Select, Tree, Tag
} from 'antd';
import {
    SearchOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';

import {api_index} from '@/store/model/reducers/index'
import {Dispatch} from 'redux';

import { TweenOneGroup } from 'rc-tween-one';

import './adminManagement.less'

interface Interfaceprops {
    pageSizeOptions: string[],
    api_all: (name:string, data: API.InterfaceData) => void,
    list: {name: string}[],
    closable: boolean,
    onDelete?: (id: number| string) => void
}

const Admin: React.FC<Interfaceprops> = (props) => {

    const [ajaxData, setAjaxData] = useState({})
    const [tags, settags] = useState([])

    const onSelect = useCallback((selectedKeys: React.Key[], info: any) => {
        console.log('selected', selectedKeys, info);
    }, [])

    const deleteMember = useCallback((id) => {
        console.log(id)
        if(props.onDelete) props.onDelete(id)
    }, [])

    const forMap = (item: any) => {
        const tagElem = (
            <Tag closable = {props.closable}
                onClose={(e: any) => {
                    e.preventDefault();
                    deleteMember(item.id);
                }}>
                {item.employeeName}
            </Tag>
        );
        return <span key={item.id} style={{ display: 'inline-block' }}>{tagElem}</span>;
    };

    useEffect(() => {
        settags([])
    }, [props.list])

    return (
        <div className="select-member-box">
            <div className="title">已选成员（{props.list.length}）</div>
            <div className="member-box">

                <TweenOneGroup
                    enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: 'from',
                        duration: 100,
                        onComplete: (e: any) => {
                            e.target.style = '';
                        },
                    }}
                    leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                    appear={false}
                >
                    {props.list.map(forMap)}
                </TweenOneGroup>
            </div>
        </div>
    )
}

const mapStateProps = (state: any) => {
    return {
        pageSizeOptions: state.golbal.pageSizeOptions,
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        api_all: (name:string, data: API.InterfaceData, ) => {
            dispatch(api_index({...data})(name))
        }
    }
};

export default connect(mapStateProps, mapDispatchToProps)(Admin);
