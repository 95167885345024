import React, {useEffect, useState} from 'react';
import RouterBox from "@/components/routerFn";

import '@/router/resgisterApps'
import Header from "@/components/header";
import {ajax} from "@/store/midPromise"

import { connect } from "react-redux";

// header头部 menu
const MainBox = (props: any) => {
    const {routes} = props;
    // console.log(routes)
    const [menuData, setMenuData] = useState<Array<any>>([])
    const menuList = props.menuList

    const mapMenu = (data: Array<any>) => {
        const arr = [...data].map(item => {
            let obj = {}
            menuList.forEach((it:any) => {
                if(it.code === item.code)  obj = {...item, ...it}
            })
            return obj
        })
        setMenuData([menuList[0],...arr])
        // console.log(arr)
    }
    const init = () => {
        ajax.get('/auth/system/config/menu').then(res => {
            if(res.status !== 200) return
            // setMenuData(res.data)
            mapMenu(res.data.menuList.concat(res.data.applyList))
            localStorage.setItem('menuList', JSON.stringify(res.data))

        })
    }
    useEffect(() => {
        init()
    },[])
    return (
        <div className="c_flex c_flex_col" style={{height: '100%', background: '#efefef'}}>
            <Header routesArr={menuData} />
            {/*子应用挂载位置*/}
            <div id='subapp-container'></div>
            <RouterBox routers={routes} mainRedirt={true} />
        </div>
    );
}
const mapStateProps = (state: any) => {
    return {
        menuList: state.golbal.arrConfig.menuList
    }
}
export default connect(mapStateProps)(MainBox);
