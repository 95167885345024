import { registerMicroApps, start } from 'qiankun';
import React from "react";
import ReactDOM from 'react-dom';
import {Spin, Alert} from 'antd';

/**
 * 渲染子应用
 */
// @ts-ignore
function Render(props) {
  const { appContent, loading } = props;
  return (
    <>
      {loading && (
          <div style={{width: '100%', height: '100%', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 100}}>
              <Spin tip="loading...">
                  <Alert
                      message="正在登录，请稍后..."
                      description=""
                      type="info"
                  />
              </Spin>
          </div>
)}
  <div dangerouslySetInnerHTML={{ __html: appContent }} />
  </>
)
}
// @ts-ignore
function render({ appContent, loading }) {
  const container = document.getElementById('subapp-container');
  ReactDOM.render(
    <Render appContent={appContent} loading={loading} />,
  container,
)
}
// @ts-ignore
function genActiveRule(routerPrefix) {
  // @ts-ignore
  return location => location.pathname.startsWith(routerPrefix);
}
// console.log(registerMicroApps)
// console.log(global, process.env);

interface listProps {
    name: string,
    entry_dev: string,
    entry_test: string,
    entry_pro: string,
    genActiveRule: string,
}

const appList = global.SUB_APP_LIST.map( (x:listProps) => {
    return {
      name: x.name,
      entry: process.env.NODE_ENV === "development"? x.entry_dev : process.env.REACT_APP_PROXY_URL === 'dev'? x.entry_test : x.entry_pro,
      render: render,
      activeRule: genActiveRule(x.genActiveRule)
    }
})

// console.log(appList)

registerMicroApps(
  [...appList],
  {
    beforeLoad: [
      // @ts-ignore
      app => {
        console.log('[LifeCycle] before load %c%s', 'color: green;', app.name);
      },
    ],
    beforeMount: [
      // @ts-ignore
      app => {
        console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
      },
    ],
    afterUnmount: [
      // @ts-ignore
      app => {
        console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
      },
    ],
  }
);

start({
  prefetch: true,
  jsSandbox: true,
  singular: true,
  fetch: window.fetch,
});
