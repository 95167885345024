import React, {useEffect, useState, useCallback} from "react";
import {
    Button, Drawer,
    Form, Input,
    Modal,
    Select,
    Table,
    Radio, message
} from 'antd';

import {Dispatch} from "redux";
import {connect} from "react-redux";
import {
    ExclamationCircleOutlined
} from '@ant-design/icons';

import {RouteComponentProps} from "react-router";
import {ajax} from "@/store/midPromise";

const { confirm } = Modal;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    typeArr: any,
    get_mock: () => void,
    api_all: (name:string, data: API.InterfaceData) => void,
}

const { Search } = Input;
const { Option } = Select;


const Supplier:React.FC<Interfaceprops> = (props) => {
    const windowObj = global.windows || window;

    const [resData, setresData] = useState({total: '', page: [], loading: true})
    const [opeData, setopeData] = useState({flag: false, title: '', id: ''})
    const [ajaxData, setAjaxData] = useState({
        supplierName: '',
        supplierStatus: '',
        nature: '',
        page: 1,
        rows: 10
    })

    const [formSet] = Form.useForm();

    const restForm = () => {
        formSet.resetFields()
    }
    const add = () => {
        restForm()
        formSet.setFieldsValue( {nature: '0'} );
        setopeData({...opeData, ...{flag: true, title: '新增供应商'}})
    }
    const edit = (data: any) => {
        setopeData({flag: true, title: '编辑', id: data.id})
        formSet.setFieldsValue( data );
    }
    const formPush = (data: any) => {
        if(opeData.title === '新增供应商') {
            ajax.post(`/Non-eeds/admin/business/supplier/add`, data).then((res: any) => {
                if(res && res.status === 200) {
                    setopeData({...opeData, ...{flag: false, title: ''}})
                    message.success('成功')
                    init()
                }
            })
        }
        if (opeData.title === '编辑') {
            ajax.put(`/Non-eeds/admin/business/supplier/update/${opeData.id}`, data).then((res: any) => {
                if(res && res.status === 200) {
                    setopeData({...opeData, ...{flag: false, title: ''}})
                    message.success('成功')
                    init()
                }
            })
        }
    }
    const changeStatus = (data: any) => {
        const text = windowObj.GLOBAL_LIBS.getStatus(data.supplierStatus, true, true)
        const status = windowObj.GLOBAL_LIBS.getStatus(data.supplierStatus, false, true)

        confirm({
            title: `确认要对该供应商进行【${text}】吗？`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk() {
                ajax.put(`/Non-eeds/admin/business/supplier/status/${data.id}`, {status}).then((res: any) => {
                    if(res && res.status === 200) {
                        message.success('成功')
                        init()
                    }
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    const init = useCallback(() => {
        ajax.get(`/Non-eeds/admin/business/supplier/list`, {params: ajaxData}).then((res: any) => {
            setresData((r) => {return {...r, ...{loading: false}} } )
            if(res && res.status === 200) setresData({...res.data})
        })
    }, [ajaxData])

    useEffect (() => {
        init()
    }, [ajaxData, init])

    const columns = [
        {
            title: '供应商名称',
            dataIndex: 'supplierName',
            width: 220,
        },
        {
            title: '供应商性质',
            dataIndex: 'nature',
            width: 120,
            render: (data: any) => <div>{data==='10'? '外部': data==='0'? '内部': '--'}</div>
        },
        {
            title: '统一信用代码',
            dataIndex: 'code',
            width: 180,
        },
        {
            title: '公司地址',
            dataIndex: 'address',
            width: 180,
        },
        {
            title: '供应商状态',
            dataIndex: 'supplierStatus',
            width: 120,
            render: (data: any) => <div>{data==='10'? '已启用': data==='20'? '已停用': '--'}</div>,
        },
        {
            title: '操作',
            width: 180,
            fixed: "right" as "right",
            render: (data: any) => <>
                <Button type={'link'} size={'small'} onClick={() => edit({...data})}>编辑</Button>
                <Button type={'link'} size={'small'} onClick={() =>  changeStatus(data) }>{windowObj.GLOBAL_LIBS.getStatus(data.supplierStatus, true, true)}</Button>
            </>
        },
    ];
    const layout = {
        labelCol: { span: 8},
        wrapperCol: { span: 18 },
        onFinish: formPush,
        form: formSet
    };
    const tableOptions = {
        rowKey: 'id',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: columns,
        dataSource: resData.page,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }


    return (
        <div className="content-page">

            <div className='c_flex c_flex_up c_flex_rl' style={{marginBottom: 20}}>
                <div>
                    <Search placeholder="输入供应商名称/统一信用代码搜索" style={{width: 320}}
                            onSearch={value => setAjaxData({...ajaxData, ...{supplierName: value, page: 1}})} enterButton />
                    <span className='c_ml_20'>供应商性质:</span>
                    <Select defaultValue="-1" style={{ width: 120, marginLeft: 20 }}
                            onChange={(value) => setAjaxData({...ajaxData, ...{nature: value !=='-1'? value: '', page: 1}})}>
                        <Option value={'-1'} >全部</Option>
                        {
                            props.typeArr.supplier_nature.map((item: any) => {
                                return  <Option value={item.value} key={item.value} >{item.name}</Option>
                            })
                        }
                    </Select>
                    <span className='c_ml_20'>供应商状态:</span>
                    <Select defaultValue="0" style={{ width: 120, marginLeft: 20 }}
                            onChange={(value) => setAjaxData({...ajaxData, ...{supplierStatus: value !=='0'? value: '', page: 1}})}>
                        <Option value={'0'} >全部</Option>
                        {
                            props.typeArr.supplier_status.map((item: any) => {
                                return  <Option value={item.value} key={item.value} >{item.name}</Option>
                            })
                        }
                    </Select>
                </div>

                <Button type={'primary'} onClick={add}>新增</Button>
            </div>

            <Table {...tableOptions} />

            <Drawer
                width={540}
                title={opeData.title}
                placement="right"
                closable={false}
                onClose={() => {setopeData({...opeData, ...{flag: false, title: ''}})}}
                visible={opeData.flag}
            >
                <Form {...layout}>
                    <Form.Item
                        label="供应商名称"
                        name="supplierName"
                        rules={[{ required: true}]}
                    >
                        <Input placeholder='请输入'  />
                    </Form.Item>
                    <Form.Item label="供应商性质" name="nature" rules={[{ required: true, message: '请选择供应商性质'}]}>
                        <Radio.Group>
                            <Radio value={"0"}>内部</Radio>
                            <Radio value={"10"}>外部</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="统一信用代码"
                        name="code"
                        rules={[{ required: true}]}
                    >
                        <Input placeholder='请输入'  />
                    </Form.Item>
                    <Form.Item
                        label="供应商法人"
                        name="legalPerson"
                        rules={[{ required: true}]}
                    >
                        <Input placeholder='请输入'  />
                    </Form.Item>
                    <Form.Item
                        label="供应商地址"
                        name="address"
                        rules={[{ required: true}]}
                    >
                        <Input.TextArea placeholder="请输入" autoSize style={{resize: 'none'}} />
                    </Form.Item>
                    <Form.Item
                        label="供应商联系电话"
                        name="phone"
                        rules={[{ required: true}]}
                    >
                        <Input placeholder='请输入'  />
                    </Form.Item>
                    <Form.Item
                        label="银行开户名"
                        name="accountName"
                        rules={[{ required: true}]}
                    >
                        <Input placeholder='请输入'  />
                    </Form.Item>
                    <Form.Item
                        label="开户行"
                        name="beginBank"
                        rules={[{ required: true}]}
                    >
                        <Input placeholder='请输入'  />
                    </Form.Item>
                    <Form.Item
                        label="银行账号"
                        name="bankAccount"
                        rules={[{ required: true}]}
                    >
                        <Input placeholder='请输入'  />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );
}

const mapStateProps = (state: any) => {
    return {
        typeArr: state.golbal.arrConfig,
        pageSizeOptions: state.golbal.pageSizeOptions,
        member_list: state.index.member_list,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(Supplier);

