import ArrApi from '../api'

/*
* 初始化state
 */
const initState = {
    login_info: {},
    member_list: {},
    mock_list: {list:[]},
    departType: []
};

/*
*
* actions
 */
// console.log(ArrApi)
const fnArr= (data: API.InterfaceData) => {
    const obj = {}
    ArrApi.forEach((item) => {
        obj[item.name] = {
            type: item.name,
            succ: data.success,
            overFn: data.overFn,
            promise: item.methods==='get'?
                (client: any) =>client[item.methods](data.api || item.api, {params: data.data}):
                (client: any) =>client[item.methods](data.api ||item.api, data.data)
        }
    })
    return (name: string) => {
        return obj[name]
    }
}

export const api_index = function (data: API.InterfaceData) {
    return fnArr(data)
}


export const reset_customer_detail = function (data: any) {
    return {
        type: "reset_customer_detail",
        result: data
    }
}
export const departType_data = function () {
    return {
        type: "departType_data",
        promise: (client: any) => client.get('/auth/dept/type/list')
    }
}
/*
* reducer
 */
export default function reducer(state = initState, action: any) {
    // console.log(action)
    switch (action.type) {
        case "departType_data":
            return {
                ...state,
                departType: action.result.data
            }
        case "login":
            return {
                ...state,
                login_info: action.result.data
            };
        case 'get_LG_B_customer_detail':
            return {
                ...state,
                customer_detail: action.result.data
            };
        case 'reset_customer_detail':
            return {
                ...state,
                customer_detail: action.result || {}
            };
        case 'GET_MEMBER_LIST':
            return {
                ...state,
                member_list: action.result.data
            };
        case 'GET_TABLE_MOCK':
            return {
                ...state,
               mock_list: action.result.data
            };
        default:
            return state
    }
}
