import * as Api from '../api'
import ConfigArr from '../../../statics/js/config/config-data'
/*
* 初始化state
 */
const initState = {
  arrConfig: ConfigArr,
  pageSizeOptions: ['10', '20', '50'],
  companyEmail: 'hrwuu.com',
    menuList: [], // 菜单权限列表
  menuInfo: {openKey: []},
    publicInfo: {}, // 公共信息
    tracking_list: [], // 快递列表
    depart_list: [], // 部门列表
};
/*
*
* actions
 */
export const changeMenuInfo = function (data: any) {
  // 更改导航栏信息
  return {
    type:  'CHANGE_MENU_INFO',
    data
  }
}

export const changeMenuList = function (data: any) {
    // 更改菜单
    return {
        type:  'CHANGE_MENU_LIST',
        data
    }
}

export const public_info = function () {
    // 获取公共信息
    return {
        type: "GETPUBLICINFO",
        promise: (client: any) => client.get(Api.UserInfo)
    }
}
export const customer_info = function () {
    // 获取客户信息
    return {
        type: "get_customer_info",
        promise: (client: any) => client.get(Api.customerInfo)
    }
}

export const get_tracking_list = function () {
    return {
        type: "get_tracking_list",
        promise: (client: any) => client.get(Api.tracking_list)
    }
}
export const get_depart_list = function (data?: any) {
    return {
        type: "get_depart_list",
        promise: (client: any) => client.get(Api.depart_list, {params: data})
    }
}

/*
* reducer
 */
export default function reducer(state = initState, action: any) {
    // console.log(action)
    switch (action.type) {
        case 'CHANGE_MENU_LIST':
            return {
                ...state,
                menuList: action.data
            }
        case 'CHANGE_MENU_INFO':
          return {
            ...state,
            menuInfo: action.data
          };
        case "GETPUBLICINFO":
            const obj = {...action.result.data, ...{phone: action.result.data.mobile}}
            localStorage.setItem('mainAppBase', JSON.stringify(obj))
            return {
            ...state,
            publicInfo: action.result.data
          };
        case "get_customer_info":
            return {
                ...state,
                customer_info: action.result.data
            };

        case "get_tracking_list":
            return {
                ...state,
                tracking_list: action.result.data
            }
        case "get_depart_list":
            return {
                ...state,
                depart_list: action.result.data
            }
      default:
            return state
    }
}
