import React, {useEffect, useState, useCallback} from "react";
import {
    Tabs,
    Avatar,
    Descriptions,
    Button,
    Dropdown,
    Menu, Modal,
    message,
} from 'antd';

import {
    DownOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import {ajax} from "@/store/midPromise";
import EditMember from "@/views/pass/organization/member/component/editMember";
import QuitCom from "@/views/pass/organization/member/component/quit";

const { TabPane } = Tabs;
const { confirm } = Modal;

interface DetailData {
    avatar?: string,
    employeeName?: string,
    mobile?: string,
    departmentNames?: string,
    idNumber?: string,
    status?: number,
}
const menuLi10 = [
    {key: 2, name: '停用账户'},
    {key: 3, name: '员工离职'},
    {key: 4, name: '编辑'},
]
const menuLi20 = [
    {key: 3, name: '员工离职'},
    {key: 5, name: '启用账户'},
]
const menuLi30 = [
    {key: 6, name: '员工复职'},
]
function Detail(props:any) {
    const [resData, setResData] = useState<DetailData>({})
    const [menuList, setMenuList] = useState(menuLi10)
    const [memberObj, setmemberObj] = useState({flag: false, title: '', type: '', valueData: {}})
    const [quitFlag, setquitFlag] = useState({flag: false, title: '', data: {}})


    const tabsChange = (data: any) => {
        console.log(data)
    }

    const edit = (data:any) => {
        setmemberObj({flag: true, title: '编辑人员', type: 'edit', valueData: data})
    }
    const close = (data:any) => {
        confirm({
            title: `确认要对该人员【${data.employeeName}】账户进行【停用】吗？`,
            icon: <ExclamationCircleOutlined />,
            content: '',
            onOk() {
                ajax.post('/auth/employee/stop', {idList: [data.id]}).then(res => {
                    if (res.status !== 200) return;
                    message.success('停用成功！')
                    init()
                })
            }
        });
    }
    const fireMember = useCallback((data:any) => {
        if(resData.status === 10) { // 在职
            setquitFlag({flag: true, title: '离职', data: data})
        } else {
            confirm({
                title: `确认要对该人员【${data.employeeName}】账户进行【离职】吗？`,
                icon: <ExclamationCircleOutlined />,
                content: '',
                onOk() {
                    ajax.post('/auth/employee/direct/leave', {idList: [data.id]}).then(res => {
                        if (res.status !== 200) return;
                        message.success('停用成功！')
                        init()
                    })
                }
            });
        }
    }, [resData])
    const restartMember =(data:any) => {
        setquitFlag({flag: true, title: '启用', data: data})
    }

    const cancelMemberDia =() =>  setmemberObj({...memberObj, flag: false})
    const closeQuit = () => {setquitFlag({...quitFlag, flag: false})}

    const successEdit = () => {
        cancelMemberDia()
        init()
    }
    const quitSucc = () => {
        init();
        closeQuit();
    }

    const selectMethod = (key: string, data:any) => {
        console.log(key, data)
        switch (key) {
            case '2': close(data); break;
            case '3': fireMember(data); break;
            case '4': edit(data); break;
            case '5': restartMember(data); break;
        }
    }
    const handleMenuClick = (data:any) => {
        console.log(resData)
        selectMethod(data.key, resData)
    }
    const mapMenuL = (status: number) => {
        switch (status) {
            case 10: setMenuList(menuLi10); break;
            case 20: setMenuList(menuLi20); break;
            case 30: setMenuList(menuLi30); break;
        }
    }
    const goList = () => {
        props.history.push('/pass/organization/member/list')
    }
    const init = useCallback(() => {
        const id = localStorage.getItem('member_id')
        if(!id) return;
        ajax.get(`/auth/employee/detail/${id}`).then(res => {
            if(res.status !== 200) return;
            setResData(res.data)
            mapMenuL(res.data.status)
        })
    }, [])
    useEffect(() => {
        init()
    }, [init])

    const menu = (
        <Menu onClick={handleMenuClick}>
            {
                menuList.map(item => <Menu.Item key={item.key}>
                    {item.name}
                </Menu.Item>)
            }
        </Menu>
    );

    const operations =
        <Dropdown overlay={menu}>
            <Button size={'middle'} >操作 <DownOutlined /></Button>
        </Dropdown>

    return (
        <div className={'main-content c_pad_20'} >
            {/*<Drawer*/}
            {/*    title={`员工详情`} placement="right" width={900}*/}
            {/*    closable={false}*/}
            {/*    onClose={props.onClose}*/}
            {/*    visible={props.visible}*/}
            {/*>*/}
                <Button onClick={goList} className={'c_mb_20'}>返回列表</Button>
                <Tabs defaultActiveKey="1" onChange={tabsChange} tabBarExtraContent={operations} >
                    <TabPane tab="个人资料" key="1">
                        <Descriptions
                            title=""
                            bordered
                            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        >
                            <Descriptions.Item label="头像">
                                <Avatar shape="square" size={64} src={resData.avatar} /></Descriptions.Item>
                            <Descriptions.Item label="姓名">{resData.employeeName}</Descriptions.Item>
                            <Descriptions.Item label="手机号">{resData.mobile}</Descriptions.Item>
                            <Descriptions.Item label="部门">{resData.departmentNames}</Descriptions.Item>
                            <Descriptions.Item label="身份证号码">{resData.idNumber}</Descriptions.Item>
                        </Descriptions>
                    </TabPane>
                </Tabs>

                {  // 停用单个
                    quitFlag.flag &&
                    <QuitCom {...quitFlag}
                             onClose={closeQuit}
                             onSucc={quitSucc} />
                }
                <EditMember {...memberObj} onChangeValue={(flag:boolean) => {setmemberObj({...memberObj, ...{flag: flag}})}}
                            onSucc={successEdit}  />
            {/*</Drawer>*/}
        </div>
    );
}

export default Detail;
