import React, {useEffect, useState, useCallback} from "react";
import {connect} from 'react-redux';
import {
    Input, Button, Table,
    message, Modal
} from 'antd';

import './index.less';

import {Dispatch} from 'redux';

import AdminManagement from '../components/adminManagement'
import {RouteComponentProps} from "react-router"
import {ajax} from "@/store/midPromise";
import {steamroller} from "@/statics/js/libs";

const { Search } = Input;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    api_all: (name:string, data: API.InterfaceData) => void,

}
interface OpeData {
    visible: boolean,
    title: string,
    type: string,
    selectName: any[],
    managementList: any[],
    roleList: any[],
    userId: number
}

function mapArr (data: any) {
    return  data.map((item: any) => {
        if(item.children) {
            return [mapArr(item.children)]
        } else {
            return item.id
        }
    })
}
const Product:React.FC<Interfaceprops> = (props) => {

    const [resData, setresData] = useState({total: '', data: [], loading: true})
    const [opeData, setopeData] = useState<OpeData>({
        visible: false,
        title: "",
        type: "",
        userId: -1,
        selectName: [],
        managementList: [],
        roleList: []
    })
    const [ajaxData, setAjaxData] = useState({
        searchName: '',
        type: 3,
        page: 1,
        rows: 10
    })

    const editAdmin = (data: any) => {
        const arr = steamroller(mapArr(data.basciList))
        console.log(data.basciList, arr)
        setopeData({...opeData, ...{
            visible: true, title: '编辑管理员'+ data.name, type: 'edit',
                selectName: [],
                userId: data.userId,
                managementList: data.rangeIdList,
                roleList: arr || []
        }})
    }
    const addAdmin = () => {
        setopeData({...opeData, ...{
            visible: true, title: '添加管理员', type: 'add',
                selectName: [],
                roleList: [],
                managementList: []
        }})
    }
    const deleteAdmin = (data: any) => {
        Modal.confirm({
            title: '提示',
            content: `确定要取消【${data.name}/${data.mobile}】基础管理员权限？`,
            onOk: () => {
                ajax.post('/auth/admin/basic/cancel', {userId: data.userId}).then(res => {
                    if(res.status !== 200) return;
                    message.success('取消成功')
                    init()
                })
            }
        })
    }
    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            width: 220,
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            width: 120,
        },
        {
            title: '部门',
            dataIndex: 'departmentName',
            width: 100,
        },
        {
            title: '管理范围',
            dataIndex: 'rangeName',
            width: 180,
            render: (data:string) => data && data.split('、').map((item, index) =>
                <div key={index}>{item}</div>)
        },
        {
            title: '基础应用权限',
            dataIndex: 'basciList',
            width: 120,
            render: (data:any[]) => data.map(item => <div key={item.id}>{ item.name }</div>)
        },
        {
            title: '操作',
            width: 180,
            fixed: "right" as "right",
            render: (data: any) => <>
                <Button type={'link'} size={'small'} onClick={() => editAdmin(data) }>编辑</Button>
                <Button type={'link'} size={'small'} onClick={() => deleteAdmin(data)}>取消管理员</Button>
            </>
        },
    ];
    const tableOptions = {
        rowKey: 'userId',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: columns,
        dataSource: resData.data,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }
    const init = useCallback(() => {
        ajax.get(`/auth/admin/list`, {params: ajaxData}).then((res: any) => {
            setresData((r) => {
                return {...r, ...{loading: false}}
            })
            if(res && res.status === 200) setresData({...res})
        })
    }, [ajaxData])
    useEffect (() => {
        init()
    }, [ajaxData, init])

    return <div>
        <div className="content-page">

            <div className='c_flex c_flex_up c_flex_rl' style={{marginBottom: 20}}>
                <div>
                    <Search placeholder="搜索管理员" style={{width: 320}}
                            onSearch={value => setAjaxData({...ajaxData, ...{searchName: value, page: 1}})} enterButton/>
                </div>

                <Button type={'primary'} onClick={addAdmin}>添加管理员</Button>
            </div>

            <Table {...tableOptions}/>

            {/*// 管理员操作2 */}
            {
                opeData.visible &&
                <AdminManagement {...opeData} onClose={() => setopeData({...opeData, ...{visible: false}})}
                                 onSuccess = { () => {
                                     setopeData({...opeData, ...{visible: false}});
                                     init()
                                 }} />
            }

        </div>
    </div>
}

const mapStateProps = (state: any) => {
    return {

    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(Product);
