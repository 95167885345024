
import React, {
    // useCallback, useEffect,
    useState} from 'react';
import {Dispatch} from "redux";
import {connect} from "react-redux";

import {Steps, Form, Input, Button, Result, Row, Col} from 'antd';
import SmsButton from '@/components/smsButton'
import {ajax} from "@/store/midPromise";
import { withRouter} from "react-router-dom";

const { Step } = Steps;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
const AccountDialog: React.FC = (props: any) => {
    const [step, setStep] = useState(1)
    const [mock, setMock] = useState('')
    const [formAccountNew] = Form.useForm()

    const companyL = JSON.parse(localStorage.getItem('companyList') || '[]')
    const finish1 = (data:any) => {
        ajax.post('/auth/user/check/password', {...data, ...{loginAccount:props.publicInfo.loginAccount}}).then(res => {
            if(res.status !==200) return;
            setStep(2)
        })
    }

    const finish2 = (data: any) => {
        ajax.post('/auth/user/update/loginAccount', {
            ...data,
            loginAccount: props.publicInfo.loginAccount
        }).then(res => {
            if(res.status !==200) return;
            setStep(3)
            setTimeout(() => {
                props.history.push('/')
            }, 3000)
        })
    }

    return (
        <>
            <div>
                <Steps size={'small'} labelPlacement={'vertical'}>
                    <Step status={step !== 1 ? "finish": "process"} title="验证身份" />
                    <Step status={step === 1 ? "wait": step === 2? "process": "finish"} title="修改账号"  />
                    <Step status={step === 3 ? "finish" : "wait"} title="修改成功" />
                </Steps>
            </div>

            {
                step === 1 &&
                <div className='modify_box' key={'1'}>
                    <p className="c_text_center tip">为了保证账户安全，请先完成身份验证</p>
                    <div style={{width: '80%'}}>
                        <Form {...layout} name="basic"
                              onFinish={finish1}
                              onFinishFailed={() => {}}
                        >
                            <Form.Item label="登录账号" name="loginAccount" >
                                <div>{props.publicInfo.loginAccount}</div>
                            </Form.Item>

                            <Form.Item label="登录密码" name="password" rules={[{ required: true, message: '请输入密码!' }]}>
                                <Input.Password />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" htmlType="submit">
                                    下一步
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            }
            {
                step === 2 &&
                    <div className='modify_box'>
                        <p className="c_text_center tip">您加入的{companyL.length}家公司将使用新账号登录</p>
                        <div style={{width: '80%'}}>
                            <Form {...layout} name="basic"
                                  form = {formAccountNew}
                                  onFinish={finish2} onFinishFailed={() => {}}
                            >
                                <Form.Item label="登录账号" name="mobile" rules={[{ required: true, message: '请输入登录账号!' }]}>
                                        <Input placeholder={'请输入手机号码（登录时的个人账号）'} onChange={() => setMock(formAccountNew.getFieldValue('mobile'))} />
                                </Form.Item>

                                <Form.Item label="验证码" name="code" rules={[{ required: true, message: '请输入验证码!' }]}>
                                    <Row style={{width: '100%'}}>
                                        <Col span={12}>
                                            <Input placeholder={'请输入短信验证码'} />
                                        </Col>
                                        <Col span={12}><SmsButton phone={Number(mock)} /></Col>
                                    </Row>
                                </Form.Item>

                                <Form.Item {...tailLayout}>
                                    <Button type="primary" htmlType="submit">
                                        确定
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
            }
            {
                step === 3 &&
                <div className='modify_box'>
                    <Result status="success"
                            title="修改成功"
                            subTitle=""
                            extra={[
                                <Button type="primary" key="console" onClick={() => props.history.push('/')}>
                                    重新登录
                                </Button>
                            ]}
                    />
                </div>
            }


        </>
    );
}

const mapStateProps = (state: any) => {
    return {
        publicInfo: state.golbal.publicInfo,
        customer_info: state.golbal.customer_info
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(withRouter(AccountDialog))
