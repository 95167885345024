import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {
    Result, Input, Select, Button, Table,
    message, Modal
} from 'antd';

import './index.less';

import {api_index} from '@/store/model/reducers/index'
import {Dispatch} from 'redux';

import AdminManagement from '../components/adminManagement'
import {RouteComponentProps} from "react-router"
import {ajax} from "@/store/midPromise";

const { Search } = Input;
const { Option } = Select;

interface Interfaceprops extends RouteComponentProps{
    pageSizeOptions: string[],
    publicInfo: {userId: number},
    api_all: (name:string, data: API.InterfaceData) => void,
}

const Product:React.FC<Interfaceprops> = (props) => {

    const [resData, setresData] = useState({total: '', data: [], loading: true})
    const [opeData, setopeData] = useState({visible: false, title: "", type: "", userId: -1})
    const [ajaxData, setAjaxData] = useState({searchName: '', page: 1,
        type: 2,
        rows: 10})

    const deleteAdmin = (data: any) => {
        Modal.confirm({
            title: '提示',
            content: `确定要取消【${data.name}/${data.mobile}】超级管理员权限？`,
            onOk: () => {
                ajax.post('/auth/admin/super/cancel', {userId: data.userId}).then(res => {
                    if(res.status !== 200) return;
                    message.success('取消成功')
                    init()
                })
            }
        })
    }
    const addAdmin = () => {
        setopeData({...opeData, ...{visible: true, title: '添加管理员', type: 'superRole_add',selectName: [],}})
    }
    const changeRole = (data: any) => {
        setopeData({...opeData, ...{visible: true, title: '转移权限' + data.name,
                type: 'superRole_change',
                userId: data.userId,
                managementList: data.rangeIdList
            }})
    }
    const onCloseAdmin = () => setopeData({...opeData, ...{visible: false}})

    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            width: 220,
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            width: 120,
        },
        {
            title: '部门',
            dataIndex: 'departmentName',
            width: 100,
        },
        {
            title: '管理范围',
            dataIndex: 'rangeName',
            width: 120,
            render: (data:any) => <div>{ data }</div>
        },
        {
            title: '操作',
            width: 180,
            fixed: "right" as "right",
            render: (data: any) => <>
                {
                    [
                        data.isTransfer === 1 &&  props.publicInfo.userId === data.userId &&
                        <Button type={'link'} size={'small'} onClick={() => changeRole(data) }>转移权限</Button>,
                        data.isCancel === 1 &&  props.publicInfo.userId !== data.userId &&
                        <Button type={'link'} size={'small'} onClick={() => deleteAdmin(data) }>取消管理员</Button>
                    ]
                }
            </>
        }
    ];
    const tableOptions = {
        rowKey: 'userId',
        scroll:{x: 'max-content', y: 540},
        loading: resData.loading,
        columns: columns,
        dataSource: resData.data,
        onChange: (data:any) => setAjaxData({...ajaxData, ...{page: data.current, rows: data.pageSize}}),
        pagination: {total: Number(resData.total), pageSize: ajaxData.rows, current: ajaxData.page, pageSizeOptions: props.pageSizeOptions, showSizeChanger: true}
    }
    const init = () => {
        ajax.get(`/auth/admin/list`, {params: ajaxData}).then((res: any) => {
            setresData((r) => {
                return {...r, ...{loading: false}}
            })
            if(res && res.status === 200) setresData({...res})
        })
    }
    useEffect (() => {
        init()
    }, [ajaxData])

    return <div>
        <div className="content-page">

            <div className='c_flex c_flex_up c_flex_rl' style={{marginBottom: 20}}>
                <div>
                    <Search placeholder="搜索管理员" style={{width: 320}}
                            onSearch={value => setAjaxData({...ajaxData, ...{searchName: value, page: 1}})} enterButton/>
                </div>

                <Button type={'primary'} onClick={addAdmin}>添加管理员</Button>
            </div>

            <Table {...tableOptions}/>

            {/* 管理员操作 */}
            {
                opeData.visible &&
                <AdminManagement {...opeData} onClose={ onCloseAdmin }
                                 onSuccess = { () => {init();onCloseAdmin()} }/>
            }
        </div>
    </div>
}

const mapStateProps = (state: any) => {
    return {
        publicInfo: state.golbal.publicInfo
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(Product);
