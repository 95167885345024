
import React, {} from 'react';

import { Tabs, Descriptions } from 'antd';

import {Dispatch} from "redux";
import {connect} from "react-redux";
import './header.less';

const { TabPane } = Tabs;

const sexType = (value: number) => {
    let result = ''
    switch (value) {
        case 1: result = '男'; break;
        case 2: result = '女'; break;
    }
    return result
}
const idCardType = (value: number) => {
    let result = ''
    switch (value) {
        case 1: result = '身份证'; break;
        case 2: result = '护照'; break;
        case 3: result = '其他'; break;
    }
    return result
}

const BaseTab: React.FC<{userInfo: any}> = ({userInfo}) => {
    const list = [
        {
            label: '姓名',
            value: 'employeeName'
        },
        {
            label: '性别',
            value: "sex",
            render: (data: any) => <span>{sexType(data)}</span>
        },
        {
            label: '手机号码',
            value: 'mobile'
        },
        {
            label: '邮箱',
            value: 'email'
        },
        {
            label: '证件类型',
            value: "idType",
            render: (data: any) => <span>{idCardType(data)}</span>
        },
        {
            label: '证件号码',
            value: 'idNumber'
        },
        {
            label: '生日',
            value: 'birthday'
        },
        {
            label: '婚姻状况',
            value: 'marriage'
        },
        {
            label: '家庭地址',
            value: 'address'
        },
        {
            label: '紧急联系人姓名',
            value: 'urgentName'
        },
        {
            label: '紧急联系人电话',
            value: 'urgentPhone'
        },
    ]
    return (
        <Descriptions title="" column={2}>
            {
                list.map((li, index) => <
                    Descriptions.Item label={li.label} key={index}>
                    {
                        li.render ? li.render(userInfo[li.value]) :
                        userInfo[li.value]
                    }
                </Descriptions.Item>)
            }
        </Descriptions>
    )
}


const InfoTabs: React.FC<{currentUser: any}> = (props: any) => {

    const panes = [
        { title: '基础信息', content: <BaseTab userInfo={props.currentUser.basicInfo} />, key: '1' },
        { title: '岗位信息', content: '暂无', key: '2', disabled: true },
        { title: '合同信息', content: '暂无', key: '3', disabled: true  },
        { title: '银行卡信息', content: '暂无', key: '4', disabled: true  },
        { title: '工作经历', content: '暂无', key: '5', disabled: true  },
        { title: '教育经历', content: '暂无', key: '6', disabled: true  },
        { title: '培训经历', content: '暂无', key: '7', disabled: true  },
        { title: '员工证照', content: '暂无', key: '8', disabled: true  },
    ];

    return (
        <>
            <Tabs defaultActiveKey="1" onChange={() => {}}>
                {panes.map(pane => (
                    <TabPane tab={pane.title} key={pane.key} disabled={pane.disabled}>
                        {pane.content}
                    </TabPane>
                ))}
            </Tabs>
        </>
    );
}

const mapStateProps = (state: any) => {
    return {
        publicInfo: state.golbal.publicInfo,
        customer_info: state.golbal.customer_info
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(InfoTabs)
