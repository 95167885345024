import {
  createStore,
  applyMiddleware,
  compose
} from 'redux';

import thunk from 'redux-thunk';
import combineReducers from './model/index';
import promiseMiddleware from './midPromise';

// import {persistStore, persistReducer} from 'redux-persist';
// import storage from 'redux-persist/lib/storage'

const middleware = [ thunk ]
// const persistConfig = {
//     key: 'root',
//     storage,
// }
// const persistedReducer = persistReducer(persistConfig, combineReducers)

const composeEnhancers =
  typeof global === 'object' &&
    global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
const enhancer = composeEnhancers(
  applyMiddleware(
    promiseMiddleware,
    ...middleware
  )
)

const storeold = createStore(
    combineReducers,
  enhancer
);

// export const store = createStore(persistedReducer, enhancer)
// export const persistor = persistStore(store)

export default storeold
