import React from "react";
import {connect} from 'react-redux';

import {Dispatch} from 'redux';

import './adminManagement.less'
import {TreeDepartment} from "@/components/select";

interface Interfaceprops {
    onChange?: (data: any) => void,
    disabled?: boolean,
    list: any[],
}
const Admin: React.FC<Interfaceprops> = (props) => {

    const changeManageScope = (value: any) => {
        props.onChange && props.onChange(value)
    }

    return (
        <div className="management-scope">
            <div className="title">管理范围</div>
            <div className="box">
                <TreeDepartment value={props.list} multiple={true}
                                placeholder="请选择管理范围"
                                disabled={props.disabled}
                                onChange={ changeManageScope } />
            </div>
        </div>
    )
}

const mapStateProps = (state: any) => {
    return {
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {

    }
};

export default connect(mapStateProps, mapDispatchToProps)(Admin);
